import React from 'react';
import styles from './index.module.scss';
import TaxonomySimple from './TaxonomySimple.js';

let IAB = window.IAB || {};

class Audience extends React.Component {
  constructor(props) {
    super(props);

    var id = props.id || 1;

    this.state = {
      audience: { name: 'Loading...' },
      audienceId: id
    };

    this.updateAudience(id);

    this.handleChange = this.handleChange.bind(this);
  }

  updateAudience(id) {
    this.setFlashState(true);
    var me = this;
    IAB.dataLabel.getAudience({ id: id }).then(function(data) {
      var wrapper = data || {success: false, result: {name: 'Audience not found', id: id}, messages: ['Audience ' + id + ' Not found' ]};
      var seg = IAB.dataLabel.unwrapResult(wrapper);
      if(seg == null){
        seg = wrapper.result;
      }
      me.setState({ segment: seg });
      me.setFlashState(false);
    });
  }

  setFlashState(visible) {
    var elem = document.getElementById('flashCube');
    if (!elem) {
      return;
    }
    if (visible) {
      elem.classList.add('on');
    } else {
      elem.classList.remove('on');
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleChange(event) {
    var id = event.target.value;
    this.updateAudience(id);
  }

  render() {
    var seg = this.state.segment;
    var exists = seg && seg.id != null;
    var taxLines = '';
    var taxes = exists ? (seg.taxonomies || []) : [];

    if (exists) {
      taxLines = taxes.map(tax => (
        <TaxonomySimple taxonomy={tax} key={tax.id} />
      ));
    }
    else{
      seg = {name: "Error"}
    }
    return (
      <div>
        <div
          id="flashCube"
          className={[styles.flashCube, 'on'].join(' ')}
        ></div>
        <div>
          <label>Audience Id</label>
          <input id="segmentLabel" type="text" onChange={this.handleChange} />
        </div>
        <div className="segmentView" id="notifyBar">
          Hello Audience <b>{seg.name}</b>
        </div>
        {taxLines}
      </div>
    );
  }
}

export default Audience;
