import React, { useContext } from 'react';
import { ImportContext } from '../../context';
import UploadForm from '../form';
import Response from '../response';
import DropDown from '../dropdown';
import styles from './index.module.scss';

const Upload = props => {
  const { completion, files, responses } = useContext(ImportContext);
  const [completed] = completion;
  const [myFiles] = files;
  const [response] = responses;

  return (
    <div className={styles.upload}>
      <DropDown />
      <div className={styles.uploadContainer}>
        <header className={styles.importHeader}>Import</header>
        <div className={styles.importDetails}>
          <p>
            If you are a data provider that doesn’t work with a participating
            Data Marketplace, you can still upload data labels directly to
            DataLabel.org via this upload tool.
          </p>
        </div>
        <UploadForm />
        {completed >= 100 && myFiles.length > 0 && response && <Response />}
      </div>
    </div>
  );
};

export default Upload;
