import React, { useState, useContext, useRef } from 'react';
import {
  Grid,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from '@mui/styles';
import { HomeContext } from '../../context';

/*
popper
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 30px)'
    }
    */

const useStyles = makeStyles(theme => ({
  base: {
    fontFamily: "'Open Sans', sans-serif"
  },
  button: {
  },
  gridContainer: {
  },
  group: {
  },
  popper: {
    width: '200px',
    zIndex: 1000
  },
  primaryButton: {
    background: 'white',
    borderColor: '#343434!important',
    color: 'black',
    '&:active, &:focus': {
      backgroundColor: 'white',
      outline: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgb(213, 213, 213)'
    }
  },
  groupBy: {}
}));

const options = ['Provider Name', 'Standard Name'];

const GroupBy = () => {
  const classes = useStyles();
  const { select, length, fetchGroupedAudiences } = useContext(HomeContext);
  // eslint-disable-next-line
  const [selected, setSelected] = select;
  // eslint-disable-next-line
  const [selectedLength, setSelectedLength] = length;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleGroupBy = group => {
    let updateGroupBy =
      group === 'Standard Name' ? 'standardIdName' : 'providerName';
    setSelected({});
    setSelectedLength(0);
    fetchGroupedAudiences(updateGroupBy);
  };

  const handleMenuItemClick = (event, index) => {
    handleGroupBy(event.target.textContent);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.groupBy}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} className={classes.gridContainer}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
            className={classes.group}
          >
            <Button
              onClick={handleToggle}
              className={`${classes.button} ${classes.primaryButton} ${classes.base} groupByButton`}
            >
              Group By: {options[selectedIndex]}
            </Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              className={`${classes.primaryButton} groupByButton_arrow`}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={'bottom-start'}
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                          className={`${classes.base} ${option}_${index}`}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </div>
  );
};

export default GroupBy;
