import React, { useState, useContext } from 'react';
import ValidationTextField from '../input';
import { ImportContext } from '../../../context';
import styles from './index.module.scss';
import uuid from 'uuid';

const PreviewTable = ({
  columns,
  rows,
  errorLines,
  updateRowsAfterDownload
}) => {
  let allUpdatedRows = {};
  const [isDownloaded, setIsDownloaded] = useState(false);
  const { removeFile, responses } = useContext(ImportContext);
  const [response] = responses;

  const mergeOldRowsWithUpdatedRows = () => {
    let mergedRows = rows.map((oldRow, rowNumber) => {
      let isRowUpdated = allUpdatedRows.hasOwnProperty(rowNumber);
      let row = oldRow;
      if (isRowUpdated) {
        let updatedRow = allUpdatedRows[rowNumber];
        row = { ...oldRow, ...updatedRow };
      }

      if (row.hasOwnProperty('hasError')) delete row.hasError;
      if (row.hasOwnProperty('errorLine')) delete row.errorLine;

      return row;
    });

    return mergedRows;
  };

  const convertArrayOfObjectsToCSV = args => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ',';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item, index) {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      if (index !== data.length - 1) {
        result += lineDelimiter;
      }
    });

    return result;
  };

  const downloadCSV = args => {
    let data, filename, link;
    let updatedRows = mergeOldRowsWithUpdatedRows();
    let csv = convertArrayOfObjectsToCSV({
      data: updatedRows
    });
    if (csv == null) return;

    filename = args.filename || 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);
    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();

    return updatedRows;
  };

  const handleDownload = () => {
    let updatedRows = downloadCSV({ filename: 'updated-audiences.csv' });
    updateRowsAfterDownload(updatedRows);
    setIsDownloaded(true);
  };

  const handleUpdatedRows = (rowNumber, colName, currVal) => {
    if (!allUpdatedRows.hasOwnProperty(rowNumber)) {
      allUpdatedRows[rowNumber] = { [colName]: currVal };
    } else {
      allUpdatedRows[rowNumber][colName] = currVal;
    }
  };

  return (
    <>
      {!response.success && (
        <div>
          <button onClick={handleDownload}>Download</button>
          {isDownloaded && <button onClick={removeFile}>Re-upload</button>}
        </div>
      )}
      <div className={styles.previewTableWrapper}>
        <table className={styles.previewTable}>
          <thead>
            <tr>
              {columns.map(col => (
                <th key={uuid.v4()}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowNumber) => (
              <tr key={uuid.v4()}>
                {columns.map(col => (
                  <td key={uuid.v4()}>
                    <ValidationTextField
                      value={row[col]}
                      rowNumber={rowNumber}
                      colName={col}
                      errorLines={errorLines}
                      originalRowErrorNumber={row.errorLine}
                      row={row}
                      handleUpdatedRows={handleUpdatedRows}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PreviewTable;
