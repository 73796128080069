import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import {Button, ClickAwayListener, Menu, MenuItem } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AudienceList from '../components/audience/AudienceList';
import AudienceService from '../services/AudienceService';


import '../scss/searchPage.scss';


/**
 * DataLabel search page
 */
function AudienceHomePage(props) {
    var IAB = window.IAB;

    const [searchTerm, setTerm] = useState('');
    const selectedItemRef = useRef({}); // ref to selected items
    const [selectedItems, setSelectedItems] = useState([]);
    //    const [count, setCount] = useState(0);
    const [displayFormat, setDisplay] = useState('list');
    const [allData, setAllData] = useState([]);
    const [displayData, setDisplayData] = useState([]);

    const formatPopupElRef = useRef(null); // element ref to menu
    const [formatMenuOpen, setFormatMenuOpen] = React.useState(false); // flag to indicate open or clased

    const formatPopupClick = (event) => {
        formatPopupElRef.current = event.currentTarget;
        setFormatMenuOpen(true);
    };

    /**
     * Helper functions to manipulate the selectedItems structure in state.
     */
    const itemHelpers = {
        clearItems : () =>{
            setSelectedItems({});
        },
        removeItem : (id) =>{
            var items = selectedItemRef.current;
            if(items[id] != null){
                delete items[id];
                setSelectedItems(itemHelpers.toValueArray(items));
            }
        },
        addItem : (id) =>{
            var items = selectedItemRef.current;
            if(items[id] == null){
                items[id] = true;
                setSelectedItems(itemHelpers.toValueArray(items));
            }
        },
        toValueArray: function(items){
            var result = [];
            for(var[k, v] of Object.entries(items)){
                result.push(k);
            }
            return result;
        }
    }

    function closeFormatMenuPopup(){
        formatPopupElRef.current = null;
        setFormatMenuOpen(false);
    }

    const selectDisplayClick = (event) => {
        var el = event.currentTarget;
        var key = el.getAttribute('data-display');
        setDisplay(key);
        closeFormatMenuPopup()
    };

    const handleCloseDisplayFormatMenu = (event) => {
        if (formatPopupElRef.current && formatPopupElRef.current.contains(event.target)) {
            return;
          }
      
        closeFormatMenuPopup()
    }

    const searchDebounce = useRef(0);

    function executeLocalSearchFilter(term) {
        if (typeof (term) !== 'string' || term.length === 0) {
            setDisplayData(allData);
        }

        var norm = term.toLowerCase();

        var result = allData.filter(aud => {
            var n = aud.searchBlob;
            if (n.indexOf(norm) > -1) {
                return true;
            }

            return false;
        })

        setDisplayData(result);
    }

    function displayFormatLabel() {
        let obj = {
            dropdown: 'List Display',
            label: 'List'
        }
        var n = 'List';
        if (displayFormat === 'taxonomy') {
            n = 'Standard Name';
            obj.label = n;
            obj.dropdown = 'Group By: ' + n;
        }
        else if (displayFormat === 'provider') {
            n = 'Provider';
            obj.label = n;
            obj.dropdown = 'Group By: ' + n;
        }

        return obj;
    }


    useEffect(() => {
        window.clearTimeout(searchDebounce.current);
        searchDebounce.current = setTimeout(function () {
            executeLocalSearchFilter(searchTerm);
        }, 300);
        //  setCount(cnt);
    }, [searchTerm]);

    useEffect(() => {
        console.log('Initial all data loaded - init view');
        setDisplayData(allData);
    }, [allData]);

    useEffect(() => {
        async function fetchData() {
            console.log('All data effect', allData);
            
            var data = await AudienceService.allAudiences(1, 500);
            if (data.success) {
                setAllData(data.result);
            }
        }
        fetchData();
        //  setCount(cnt);
    }, []);

    const itemSelectedHandler = useCallback((id, isChecked) => {
        console.log('audience ' + id + ' ischecked? ' + isChecked);
        if(isChecked){
            itemHelpers.addItem(id);
        }
        else{
            itemHelpers.removeItem(id);
        }
    }, []);

    const openCompareWindow = (event) => {
        var i, url;
        url = '/labels/compare/'
        var items = selectedItems.join('-');
        document.location = url + items;

        return true;
    }


    var compareDisabled = selectedItems.length > 1 ? {} : {disabled:true};

    return (
        <div className="pageContainer">

            <div className="flexbox navbar listPageHeader">
                <div className="flex1 pull-right listControlWrap">
                    <div className="flex1 displaySelectWrap">
<button id="compareButton" type="button" color="success" {...compareDisabled} 
    onClick={openCompareWindow}
    className="greenButton" >Compare</button>
                    </div>

                    <div className="flexbox listHeaderTools" >
                        <div className="displayStyleWrap">
                            <div className="displayDropdown">
                                <Button
                                    id="listFormatDropdown"
                                    className="popperButton btn btn-outline-secondary"
                                    aria-controls="formatStyleMenu"
                                    aria-haspopup="true"
                                    aria-expanded={formatMenuOpen}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    onClick={formatPopupClick}
                                >{displayFormatLabel().dropdown}
                                </Button>
                                <ClickAwayListener onClickAway={handleCloseDisplayFormatMenu}>
                                <Menu
                                    id="formatStyleMenu"
                                    anchorEl={formatPopupElRef.current}
                                    open={formatMenuOpen}
                                    onClose={closeFormatMenuPopup}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={selectDisplayClick} data-display="list" >List Display</MenuItem>
                                    <MenuItem onClick={selectDisplayClick} data-display="taxonomy">Group By: Standard Name</MenuItem>
                                    <MenuItem onClick={selectDisplayClick} data-display="provider">Group By: Provider</MenuItem>
                                </Menu>
                                </ClickAwayListener>
                            </div>
                        </div>

                        <div className="flexbox">
                            <div className="search">
                                <div className="searchIcon">
                                    <SearchIcon />
                                </div>

                                <input onChange={e => setTerm(e.target.value)}
                                    className="audienceSearch"
                                    placeholder="Search labels…" aria-label="search" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="outerContent" style={{display:"none"}}>

                <h3>Term: {searchTerm}</h3>
                <h4>Selected Items: {JSON.stringify(selectedItems)}</h4>
            </div>

            <hr />

            <AudienceList 
                data={displayData} 
                selectedItems={selectedItems}
                searchTerm={searchTerm} 
                onItemSelected={itemSelectedHandler}
                displayFormat={displayFormat} ></AudienceList>

            <div className="anAnchor padTop10"></div>

        </div>


    );
};

export default withRouter(AudienceHomePage);

