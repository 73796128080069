import React, { useState, useCallback, createContext } from 'react';
import { toast } from 'react-toastify';
import * as d3 from 'd3';
import Papa from 'papaparse';
import styles from './index.module.scss';

const IAB = window.IAB;

export const ImportContext = createContext();

export const ImportProvider = ({ children }) => {
  const [template, setTemplate] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [response, setResponse] = useState(null);
  const [completed, setCompleted] = useState(10);

  const notify = (isSuccess, message) => {
    toast(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      className: isSuccess ? styles.toasterSuccess : styles.toasterError,
      hideProgressBar: true,
      bodyClassName: styles.toasterBody,
      autoClose: 10000
    });
  };
  
  // FIX THIS
  const getTemplate = async () => {
    // TODO: Don't do this. We should set up an alternate endpoint to dump structure if this is desire
    let response = await IAB.dataLabel.getAudienceCsv({id: 'sample'});
    if (d3.csvParse) {
      response = d3.csvParse(response);
    } else if (d3.csv.parse) {
      response = d3.csv.parse(response);
    }

    let columns = response.columns;
    const rows = columns.map(col => response[0][col]);
    setTemplate([columns, rows]);
  };

  const readFile = fileToRead => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsText(fileToRead);
      reader.onload = async e => {
        const results = Papa.parse(e.target.result, { header: true });
        const rows = results.data;
        return resolve({ /*match: true,*/ fileToAdd: rows });
      };
    })
      .then(data => data)
      .catch(err => console.log(err));
  };

  // eslint-disable-next-line
  const onDrop = useCallback(async acceptedFiles => {
    let { fileToAdd } = await readFile(acceptedFiles[0]);
    setMyFiles([{ readFile: fileToAdd, rawFile: acceptedFiles[0] }]);
    let result = await postFormData(true);
    setResponse(result);
  });

  const postFormData = async isTest => {
    let promise;
    let result;
    let form = document.getElementById('form-upload');

    let cb = IAB.dataLabel.uploadAudienceCsv;

    if (isTest) cb = IAB.dataLabel.uploadAudienceCsvTest;

    promise = cb({
      body: form
    });

    try {
      result = await promise;
    } catch (ex) {
      console.warn('Error in Audience upload');
      result = ex;
    }

    return result;
  };

  const handleSubmit = async () => {
    let result = await postFormData(false);
    if (result.success) {
      notify(true, 'Successfully Uploaded!');
      setMyFiles([]);
      setResponse(null);
      setCompleted(10);
    } else {
      setResponse(result);
    }
  };

  const removeFile = () => {
    setMyFiles([]);
    setResponse(null);
    setCompleted(10);
  };

  return (
    <ImportContext.Provider
      value={{
        template,
        files: [myFiles, setMyFiles],
        notify,
        getTemplate,
        onDrop,
        handleSubmit,
        removeFile,
        responses: [response, setResponse],
        completion: [completed, setCompleted]
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};
