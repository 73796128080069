const d3 = require('d3');
const IAB = window.IAB;

export const getCSV = async ids => {
  let response = await IAB.dataLabel.downloadAudienceCsv(
    ids.split('-').join(',')
  );

  if (d3.csvParse) {
    response = d3.csvParse(response);
  } else if (d3.csv.parse) {
    response = d3.csv.parse(response);
  }

  const columns = response['columns'];
  const csv = [columns];

  for (let i = 0; i < response.length; i++) {
    let row = columns.map(col => response[i][col]);
    csv.push(row);
  }

  return csv;
};



