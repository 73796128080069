import React from 'react';
import Audience from './Audience';
import TaxonomySearch from './TaxonomySearch';
import AudienceUploadForm from './AudienceUploadForm';
import styles from './index.module.scss';

const ApiOverview = props => {
  return (
    <div className="wrap">
      <div className={styles.makeItNotUgly}>
        <h1>Pick Audience</h1>
        <p>Make it 1 or 2</p>

        <hr />
        <div>
          <Audience id="1" />
        </div>
      </div>
      <div className={styles.makeItNotUgly} style={{ marginTop: '15px' }}>
        <TaxonomySearch />
      </div>
      <div className={styles.makeItNotUgly} style={{ marginTop: '15px' }}>
      </div>
      <hr />
      <div id="uploadWrap">
      <AudienceUploadForm />
      </div>
    </div>
  );
};

export default ApiOverview;
