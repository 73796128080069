import React from 'react';
import styles from './index.module.scss';

let IAB = window.IAB || {};

function ParentList(props) {
  let parents = props.parents || [];
  console.log(parents);
  if (parents.length === 0) {
    return <h3>No Parents</h3>;
  }
  return (
    <ul>
      {parents.map(tax => (
        <li data-taxonomy-id={tax.id} key={'taxid_' + tax.id}>
          {tax.name}
        </li>
      ))}
    </ul>
  );
}

class TaxonomyDetail extends React.Component {
  //  let { id } = useParams();

  constructor(props) {
    super(props);

    let { id } = props.match.params;
    this.state = {
      id: id,
      tax: {},
      parents: []
    };

    this.fetchTax(id);

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {}

  componentWillUnmount() {}

  handleClick() {
    console.log('clicked ' + this.state.tax.id);
  }

  fetchTax(id) {
    var me = this;
    var obj = { id: id };

    IAB.dataLabel.taxonomyFullTree(obj).then(function(wrapper) {
      var data = wrapper.result;
      me.setState({ tax: data });
    });
    IAB.dataLabel.taxonomyParents(obj).then(function(wrapper) {
      var data = wrapper.result;
      me.setState({ parents: data });
    });
  }

  render() {
    var tax = this.state.tax;
    var kids = (tax && tax.children) || [];

    return (
      <div className="searchDetail">
        <div>
          <button
            onClick={this.handleClick}
            className={styles.boldLink}
            data-taxid={tax.id}
          >
            {tax.condensedName}
          </button>
        </div>
        <h3>Children</h3>
        <ul>
          {kids.map(k => (
            <li key={"taxkid_" + k.id}>{k.name}</li>
          ))}
        </ul>

        <hr />
        <div className="parentList">
          <h2>Parents</h2>
          <ParentList parents={this.state.parents} />
        </div>
      </div>
    );
  }
}

export default TaxonomyDetail;
