import React from 'react';
import { ToastContainer } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@mui/icons-material/GetApp';
import useMediaQuery from '@mui/material/useMediaQuery';




/**
 * Loader div
 * @param {} props 
 * @returns 
 */
 function LoadIcon(props){
    // var cssClass = props.customStyle || ''

    return (
      <Button loading="true" variant="outlined">
      Submit
    </Button>);
}


/**
 * Toast popup
 * @param {} props 
 * @returns 
 */
 function Toast(props){
    const CloseButton = ({ customCSS, closeToast }) => (
        <CloseIcon onClick={closeToast} style={customCSS} />
      );
    
      return (
        <ToastContainer
          closeButton={
            <CloseButton customCSS={{ color: 'white', margin: '.5em' }} />
          }
        />
      );
}


function DownloadCSV(props){
    const isDesktop = useMediaQuery('(min-width: 970px)');
    let downloadableCSV = props.downloadableCSV;

    var downloadButton;
    if(isDesktop){
        downloadButton = (<Button
            variant="contained"
            startIcon={
              <GetAppIcon style={{ fill: '#237940' }} fontSize="large" />
            }
            className="exportBtn greenButton"
          >
            Export
          </Button>);
    }
    else{
        downloadButton = (<Button aria-label="download" className="fab">
        <GetAppIcon style={{ fill: '#237940' }} />
      </Button>);
    }
    return(
    <CSVLink
        data={downloadableCSV}
        filename={'exportAudiences.csv'}
        className="csvLink" >
            {downloadButton}
        
    </CSVLink>
  
    );
}

  
/**
 * Top UI application bar
 * @param {} props 
 * @returns 
 */
function DataLabelAppBar(props) {
    
  return (
    <AppBar
      position="sticky"
      className="appbar appbarRoot"
    >
      {props.children}
    </AppBar>
  );
}



export {
    DataLabelAppBar,
    DownloadCSV,
    LoadIcon,
    Toast
}


