import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AudienceCard from './AudienceCard';
import Subheader from './subheader';
import { renderCards } from './utils';
import uuid from 'uuid';
import { getCSV } from '../../components/dataHelpers';
import { DataLabelAppBar, DownloadCSV } from '../../components/UiWidgetPack';

const IAB = window.IAB || {};
var unwrap = IAB.dataLabel.unwrapResult;


/**
 * Single audience render component
 * @param {*} props 
 * @returns 
 */
function Audience(props) {
  // const classes = useStyles();
  const [audience, setAudience] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadableCSV, setDownloadableCSV] = useState([]);

  useEffect(() => {
    const fetchAudience = async () => {
      const { id } = props.match.params;
      try {
        const result = await IAB.dataLabel.getAudience({ id });
        var data = unwrap(result);
        const csvToDownload = await getCSV(id);

        setDownloadableCSV(csvToDownload);
        setAudience(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchAudience();
  }, [props.match.params]);

  return (
    <div className="audienceContainer singleAudience">
      <DataLabelAppBar>
        <div className="audienceHeaderRow row">
          <div className="col-2">
          <DownloadCSV downloadableCSV={downloadableCSV} />
          </div>
          <div className="col-8">
          <Subheader title={audience && audience.name} isLoading={isLoading} />
          </div>
        </div>
      </DataLabelAppBar>

      {audience &&
        renderCards([audience]).map(({ cardTitle, cardInfo }) => (
          <AudienceCard
            isLoading={isLoading}
            cardTitle={cardTitle}
            cardInfo={cardInfo}
            key={uuid.v4()}
          />
        ))}
    </div>
  );
};

export default withRouter(Audience);
