import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { HomeContext } from '../../context';

const ColorButton = withStyles(theme => ({
  root: {
    color: 'white',
    border: 'none',
    fontFamily: "'Open Sans', sans-serif",
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    backgroundColor: '#237940',
    '&:hover': {
      backgroundColor: '#19592f'
    },
    '&:active, &:focus': {
      outline: 'none'
    }
  }
}))(Button);

const CompareButton = ({ handleComparison }) => {
  const { length } = useContext(HomeContext);

  const [selectedLength] = length;
  return selectedLength === 1 ? (
    <ColorButton
      variant="contained"
      disabled
      style={{ background: 'rgb(213, 213, 213)' }}
    >
      Pick up to 3 to Compare
    </ColorButton>
  ) : selectedLength > 1 ? (
    <ColorButton onClick={handleComparison}>Compare Labels</ColorButton>
  ) : (
    <span style={{ width: '200px' }}></span>
  );
};

export default CompareButton;
