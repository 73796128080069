import React from 'react';
import { withRouter } from 'react-router-dom';
import { ImportProvider } from './context';
import Container from './container';

const Import = () => (
  <ImportProvider>
    <Container />
  </ImportProvider>
);

export default withRouter(Import);
