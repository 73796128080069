import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { TableBody } from '@mui/material';
import GroupPanel from './grouppanel';
import GroupItem from './groupitem';
import { HomeContext } from '../../context';
import uuid from 'uuid';

const Tablebody = () => {
  const { expansion, grouped } = useContext(HomeContext);
  const [expandedGroups] = expansion;
  const [groups] = grouped;

  return (
    <TableBody>
      {groups.map((group, groupPosition) => {
        let values = group.audiences;
        let isProviderSelected = group.hasOwnProperty('company');
        let key = isProviderSelected ? group.company.name : group.taxonomy.name;

        let isCompliant = false;
        if(group && group.company){
          isCompliant = group.company.compliant;
        }

        return (
          <React.Fragment key={uuid.v4()}>
            <GroupPanel
              groupKey={key}
              isProviderSelected={isProviderSelected}
              isCompliant={isCompliant}
            />
            {expandedGroups[key] &&
              values.map((audience, audiencePosition) => (
                <GroupItem
                  audience={audience}
                  isProviderSelected={isProviderSelected}
                  key={uuid.v4()}
                />
              ))}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};

export default withRouter(Tablebody);
