import React, { useContext } from 'react';
import { TableRow, TableCell, IconButton, Icon } from '@mui/material';
import { HomeContext } from '../../../context';
import seal from '../../../assets/images/seal.png';

const styles = {
  groupExpand: {
    boxShadow: 'inset 0px -2px 0px #bebebe',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderBottom: 'none',
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.5em'
  },
  groupCollapse: {
    fontWeight: 'bold',
    cursor: 'pointer',
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.5em'
  }
};

const GroupPanel = ({ groupKey, isProviderSelected, isCompliant }) => {
  const { expansion, columns } = useContext(HomeContext);
  const [expandedGroups, setExpandedGroups] = expansion;
  const [columnData] = columns;

  const expandGroup = groupVal => {
    const curr = expandedGroups[groupVal];
    let updateExpandedGroups = { ...expandedGroups };
    updateExpandedGroups[groupVal] = !curr;
    setExpandedGroups(updateExpandedGroups);
  };

  const showCertificationSeal =
    isProviderSelected && isCompliant ? (
      <span>
        <img
          src={seal}
          alt="Compliance Seal"
          style={{ width: '40px', height: '35px', marginLeft: '1rem' }}
        />
      </span>
    ) : (
      ''
    );

  return (
    <TableRow>
      <TableCell
        colSpan={columnData.length}
        style={
          expandedGroups[groupKey] ? styles.groupExpand : styles.groupCollapse
        }
        onClick={expandGroup.bind(null, groupKey)}
      >
        <IconButton>
          <Icon>
            {expandedGroups[groupKey] ? 'expand_more' : 'chevron_right'}
          </Icon>
        </IconButton>
        <span>{groupKey}</span>
        {showCertificationSeal}
      </TableCell>
    </TableRow>
  );
};

export default GroupPanel;
