import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import uuid from 'uuid';
import Expand from './expand.js';

function AudienceCard(props) {
  var { cardTitle, cardInfo, isLoading } = props;

  var content;

  if (isLoading || cardInfo == null) {
    cardInfo = [{}, {}];

    content = (
      <div className="card">
        <Skeleton variant="rect" width={'100%'} height={70} />

        {cardInfo.map(() => (
          <div className="row" key={uuid.v4()}>
            <Skeleton
              animation="wave"
              variant="rect"
              width={'100%'}
              height={10}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              width={'100%'}
              height={10}
            />
          </div>
        ))}
      </div>
    );
  }
  else {
    content = (
      <div className="sectionBody">
        <header className="header">
          {cardTitle}
        </header>
        {cardInfo.map(({ label, fieldData }) => (
          <div className="row dataRow" key={uuid.v4()}>
            <div className="col">
              <Typography className="title">
                {label && label}
              </Typography>
            </div>

            {fieldData.map(info => {
              if (label === 'Standardized Audience Name') {
                return (
                  <div className="col">
                    <Expand key={uuid.v4()}>
                      <Typography
                        key={'info' + uuid.v4()}
                        className="info"
                      >
                        {info}
                      </Typography>
                    </Expand>
                  </div>
                );
              } else {
                return (
                  <div className="col">
                    <Typography
                      key={'info' + uuid.v4()}
                      className="info"
                    >
                      {info}
                    </Typography>
                  </div>
                );
              }
            })}
          </div>
        ))}

      </div>
    );
  }

  return (
    <div className="container audienceSection">
      <Card className="root">
        <CardContent>
          {content}
        </CardContent>
      </Card>
    </div>
  );
};

export default withRouter(AudienceCard);
