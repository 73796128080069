import React, { useContext, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import CheckBox from '../checkbox';
import { HomeContext } from '../../../context';
import seal from '../../../assets/images/seal.png';
import styles from './index.module.scss';

const CustomCell = React.forwardRef(function CustomCell(props, ref) {
  return (
    <span {...props} ref={ref}>
      {props.cellname}
    </span>
  );
});

const GroupItem = props => {
  const { isProviderSelected, audience } = props;
  // eslint-disable-next-line
  const { select, length } = useContext(HomeContext);
  const [selected, setSelected] = select;
  const [selectedLength, setSelectedLength] = length;
  const [segId] = useState(`${audience.externalId} ${audience.id}`);
  const [cellName] = useState(
    !isProviderSelected
      ? audience.provider.name
      : audience.taxonomies.reduce((taxString, currTax, currIdx, taxSrc) => {
          taxString += `${currTax.condensedName}`;
          if (currIdx !== taxSrc.length - 1) {
            taxString += ' > ';
          }
          return taxString;
        }, '')
  );

  var isCompliant = audience.provider.compliant;

  const handleSelect = () => {
    let updatedSelections = { ...selected };
    let updatedSelectedLen = selectedLength;
    if (updatedSelections.hasOwnProperty(segId)) {
      delete updatedSelections[segId];
      updatedSelectedLen--;
    } else {
      if (Object.keys(updatedSelections).length < 3) {
        updatedSelections[segId] = segId;
        updatedSelectedLen++;
      }
    }
    setSelected(updatedSelections);
    setSelectedLength(updatedSelectedLen);
  };

  const isChecked = () => {
    return selected.hasOwnProperty(segId);
  };

  const isDisabled = () => {
    return selectedLength === 3 && !selected.hasOwnProperty(segId);
  };

  return (
    <TableRow>
      <TableCell className={styles.groupItemCell}>
        <CheckBox
          checked={isChecked()}
          onChange={handleSelect}
          disabled={isDisabled()}
          iconstyle={{ fill: 'white' }}
        />
      </TableCell>
      <TableCell
        className={`${styles.groupItemCell} ${styles.groupItemCellEllipsis}`}
      >
        <Tooltip title={cellName} placement="bottom-start">
          <CustomCell cellname={cellName} />
        </Tooltip>
      </TableCell>
      <TableCell className={styles.groupItemCell}>
        <span>
          {isCompliant && (
            <img
              src={seal}
              alt="Compliance Seal"
              style={{ width: '30px', height: '25px' }}
            />
          )}
        </span>
      </TableCell>
      <TableCell className={styles.groupItemCell}>{audience.name}</TableCell>
      <TableCell className={styles.groupItemCell}>
        <Link
          to={`/audience/${audience.id}`}
          style={{ color: 'green' }}
        >
          View Details
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default withRouter(GroupItem);
