import React, { useState, useEffect, useContext, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import { IconButton, Icon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'; // , withRouter, useHistory, useLocation
import { green } from '@mui/material/colors';
import AudienceService from '../../services/AudienceService';



/**
 * Helper functions to get field names or values
 */
 var fieldFn = {
    providerName: function (a, c) {
        var n = null;
        if (a && a.provider != null && typeof (a.provider) === 'object') {
            n = a.provider.name;
        }

        if (n == null) {
            n = a.csvProviderName || (c && c.name);
        }
        return n;
    },
    aLink: function (link, text, audience, comp) {
        try {
            var token;
            var href = link;
            if (typeof (href) === 'string' && href.indexOf("{") > -1) {
                var a, b;
                a = href.indexOf('{');
                b = href.indexOf('}', a);
                token = href.substring(a + 1, b);
                href = href.substr(0, a) + audience[token] + href.substr(b + 1);
            }
            return <Link to={href}>{text}</Link>
        }
        catch (ex) {
            return <a href="#err" data-err={ex.message}>Error</a>
        }
    }

}

/**
 * Headers that are used in display
 */
var headers = [
    { l: 'Audience Name', k: 'name' },
    { l: 'Provider', fn: fieldFn.providerName },
    { l: 'Criteria', k: 'criteriaSummary' },
    { l: 'Label', link: '/audience/{id}', linkText: 'Details' }
]

/**
 * Generate a random string for repeat keys
 * @returns random 5 char string
 */
function randStr() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
}

/**
 * Generate a column key for React based on header
 * @param {object} head 
 */
function columnKey(head, seed) {
    let lbl = head.l || randStr();
    seed = seed || 'dcol_';
    return seed + lbl.replace(' ', '_');
}

/**
 * Manipulate the passed data list into an appropriate structure.
 * Also initializes display state values.
 * @param {Array} data The data label objects as an Array
 * @param {String} format Display format or grouping
 * @returns The grouped or ungrouped data
 */
function formatData(data, format) {

    if (format === 'list') {
        return data;
    }
    else if (format === 'provider' || format === 'taxonomy') {
        var grouped = AudienceService.groupAudienceList(data, format);
        grouped.groupArray.map((g) => {
            g.isExpanded = false;
            return true;
        });
        return grouped;
    }
}


    // Resolve desired value based on column definition and audience row
    function resolveValue(aud, comp, col) {
        if (typeof (col.k) === 'string') {
            return aud[col.k];
        }
        else if (typeof (col.fn) === 'function') {
            return col.fn.call(null, aud, comp);
        }
        else if (typeof (col.link) === 'string') {
            return fieldFn.aLink.call(null, col.link, col.linkText, aud, comp);
        }
        return 'something else';
    }



/**
 * Component tag Group header
 * @param {} props 
 * @returns 
 */
 function GroupHeader(props) {
    const group = props.group;
    var keySeed = "grouphead_" + group.id;
    var iconRight = <Icon className="rightIcon">chevron_right</Icon>
    var iconDown = <Icon className="downIcon">expand_more</Icon>
    var iconStateClass = (group.isExpanded) ? 'open' : '';
    var subHead = <span></span>;
    if(group.condensedName != null){
        subHead = <span className="condensedName">{group.condensedName}</span>
    }

    return (<div className="groupHeaderCell" key={keySeed}>
        <div className="flexbox"> 
        <div className="expandWrap">
        <IconButton data-groupid={group.id} className={"stateIndicator " + iconStateClass}>
            {iconRight}
            {iconDown}
        </IconButton>
        </div>
            <h3>{group.name}</h3>
            <div className="subheadWrap">{subHead}</div>
        </div>
        </div>);

}

const setClass = function(elem, css, state){
    if(elem && elem.classList){
        if(state){
            elem.classList.add(css);
        }
        else{
            elem.classList.remove(css);
        }
    }
}

const handleAccordionToggle = (groupDef, groupDomKey) => (event, isExpanded) => {
    groupDef.isExpanded = isExpanded;
    var srcElem = event.currentTarget;
    var iconWrap = srcElem.querySelector('.stateIndicator');
    var header;

    if(srcElem.classList.contains('accordionHeader')){
        header = srcElem;
    }
    else{
        var g = srcElem.closest('.groupGrid');
        header = g.querySelector('.accordionHeader');
    }

    if(isExpanded){
        setClass(iconWrap, 'open', true);
        setClass(header, 'open', true);
    }
    else{
        setClass(iconWrap, 'open', false);
        setClass(header, 'open', false);
    }
    // debugger;
//    console.log(groupDomKey, event, isExpanded);
//    debugger;
}


/**
 * Component tag for grouped data
 * @param {} props 
 * @returns 
 */
 function DataGroup(props) {
    const group = props.group;
    const groupDef = group.group;
    const items = group.items;
    var keySeed = "group_" + groupDef.id;
    var accordionWrapKey = 'accd_' + keySeed;
    var summaryKey = 'summaryHead_' + keySeed;
    let passedItems = props.selectedItems || {};
    let selectedItems = toIdHash(passedItems);
    let listener = props.onItemCheckChanged || function(){};


    const handleItemCheck =  useCallback((id, isOn) => {
        listener(id, isOn);
    }, []);



    let useAccordion = true;


    let domList = 'gonnaset'
    try {
        domList = items.map( (audience, index) => {
            const r = audience;
            const rowKey = keySeed + 'audienceRow_' + audience.id;
            const isChecked = selectedItems[r.id];
            return <DataRow 
                key={rowKey} 
                audience={r} company={r.company} 
                data-audienceid={audience.id}
                checked = {isChecked}
                onItemCheckChanged={handleItemCheck}
                keySeed={rowKey} headers={headers} ></DataRow>
        } );
        
    }
    catch (ex) {
        domList = "Excep: " + ex.message;
    }

    var domHeader = <GroupHeader group={groupDef} data-key={keySeed}  key={"header_" + keySeed} />;
    var domDetails =  <div className="groupContents">{domList}</div>;

    if(useAccordion){
        return (
            <div className="groupGrid">
                <Accordion group={groupDef} key={keySeed} id={accordionWrapKey}
                    expanded={groupDef.isExpanded} onChange={handleAccordionToggle(groupDef, accordionWrapKey)} >
                <AccordionSummary id={summaryKey} key={summaryKey} className="accordionHeader"
                    expandIcon={<ExpandMoreIcon/>}
                >{domHeader}
                </AccordionSummary>
                <AccordionDetails>
                {domDetails}
                </AccordionDetails>
                </Accordion>
            </div>
            );
        }else{
            return (
                <div className="groupGrid">
                    <div className="accordionHeader">
                    {domHeader}
                    </div>
                    {domDetails}
                </div>
                );    
        }
}

/**
 * Component tag for a list data row
 * @param {} props 
 * @returns 
 */
function DataRow(props) {
    var aud = props.audience;
    var comp = props.company;
    var headers = props.headers;
    var rowIsChecked = !!props.checked;
    const keySeed = props.keySeed;
    var listener = props.onItemCheckChanged || function(){};

    const [checked, setChecked] = useState(rowIsChecked);

    var checkUpdated = (event) => {
        var elem = event.target;
        var isChecked = elem.checked;
        setChecked(isChecked);
        listener(aud.id, isChecked);
    }

    

    var dom = 'gonnaset'
    try {
        dom = headers.map((r) => {
            let colKey = columnKey(r, keySeed);
            let val = resolveValue(aud, comp, r);
            // console.log('COLKEY: ' + colKey);
            return <div className='th' key={colKey}>{val}</div>
        });
    }
    catch (ex) {
        debugger;
        dom = "Excep: " + ex.message;
    }

    return <div className="gridRow" data-id={aud.id}  >
        <div className='th rowCheck' data-audience={aud.id} >
            <Checkbox color='success' checked={checked} onChange={checkUpdated} ></Checkbox></div>
        {dom}</div>;
}

/**
 * Convert array of items into an ID hash
 * @param {} items 
 * @returns 
 */
function toIdHash(items){
    var obj = {};
    var i;
    for(i=0;i<items.length;i++){
        obj[items[i]] = true;
    }
    return obj;
}

/**
 * Audiences in a flat list layout, or a grouped list layout. 
 * This renders items from props.data
 */
function AudienceList(props) {
    // var displayName = props.displayName || "User";
    var data = props.data;
    var format = props.displayFormat || 'list';
    var displayData = formatData(data, format);
    let passedItems = props.selectedItems || {};
    let selectedItems = toIdHash(passedItems);
    var listener = props.onItemSelected || function(){};

    console.log('AudienceList selectedItems', selectedItems);
    console.log('Passed items: ', passedItems);

    function isGrouped() {
        return (format !== 'list');
    }

    // Header row DOM
    function HeaderRow(props) {
        const dom = headers.map((r, index) =>
            <div className='th' key={"header_" + index}>{r.l}</div>);
        return <div className="gridRow"><div className='th'></div>{dom}</div>;
    }

    const handleItemCheck =  useCallback((id, isOn) => {
        listener(id, isOn);
    }, []);



    if (isGrouped()) {
        console.log(displayData);
        let groupArray = displayData.groupArray;
        
        let groupMarkup = groupArray.map( (g, index) =>{
            return <DataGroup onItemCheckChanged={handleItemCheck} selectedItems={passedItems} group={g} />
        } )


        return (

            <div className="listGrid tableGrid audienceListGrid">
                <HeaderRow />
                <div className="groupExpandWrapper">
                    {groupMarkup}
                </div>

            </div>

        );
    }
    else {
        const rows = displayData.map((r) => {
            const isChecked = (selectedItems[r.id] === true);
            const rowKey = 'audienceRow_' + r.id;
            return <DataRow audience={r} company={r.company} 
                checked={isChecked}
                key={rowKey} keySeed={rowKey} 
            onItemCheckChanged={handleItemCheck}
            headers={headers} ></DataRow>
        });

        return (

            <div className="listGrid tableGrid audienceListGrid">
                <HeaderRow />
                {rows}

            </div>

        );
    }
}

export default AudienceList;




