import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, authed, user, ...rest }) => {
  console.log('PrivateRoute', authed);
  console.log('PrivateRoute rest', rest)
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/', component: { from: props.location } }}
          />
        )
      }
    />
  );
};
