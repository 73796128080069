import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Icon } from '@mui/material';
import {UploadSidebar } from '../components/UploadSidebar';
import {useDropzone} from 'react-dropzone'

import '../scss/searchPage.scss';


// var fileForm = null;
let isSet = false;


function setFileValue(file){
    /*
    if(fileForm == null){
        var d = document.createElement('FORM');
        d.setAttribute('enctype', 'multipart/form-data');
        fileForm = new FormData(d);
    }
    */
   var form = document.getElementById('segupform');
   var elem = form.querySelector('input[type=file]');
   elem.files[0] = file;

    isSet = true;
   // fileForm.set('file', file, file.name);
}

/**
 * Obtain a reference to the FormData object
 */
function getFormData(){
    if(!isSet){
        return null;
    }
    return document.getElementById('segupform');
}


/**
 * The drop zone control
 * @returns 
 */
function MyDropzone() {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setFileValue(acceptedFiles[0]);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  var title;
  if(isDragActive){
    title = "Release and Test or Upload";
  }
  else{
    title = "Select Your File"
  }
/*
    <div className="card dropZoneCard">
    <div className="card-body" {...getRootProps()}>
        <div className="card-title centerTitle"><h5>{title}</h5></div>

      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
    </div>

*/
  return (
      <div></div>
  )
}


/**
 * DataLabel upload page - simplified
 */
function UploadLabelsPage(props) {
    var IAB = window.IAB;

    const [searchTerm, setTerm] = useState('');
    const selectedItemRef = useRef({}); // ref to selected items

    const formatPopupElRef = useRef(null); // element ref to menu
    const [formatMenuOpen, setFormatMenuOpen] = React.useState(false); // flag to indicate open or clased

    const formatPopupClick = (event) => {
        formatPopupElRef.current = event.currentTarget;
        setFormatMenuOpen(true);
    };



    function uploadFile(event) {
        console.log('start upload');
        event.preventDefault();
        var frm = document.getElementById('segupform');

//        var frm = getFormData(); // document.getElementById('segupform');
        if(frm == null){
            alert('Select a file first');
            return;
        }

        function outputResponse(data, isError){
            var elem = document.getElementById('uploadResult');
            var msg = '';
            if(isError){
                msg = 'FAILURE IN UPLOAD: <br/>';
            }
            msg += "<pre>"
            + JSON.stringify(data, null, '\t') + "</pre>";
            elem.innerHTML = msg;

        }

        outputResponse('processing...')

        IAB.dataLabel.uploadAudienceCsv({ body: frm }).then(function (rslt) {
            console.log(rslt);
            outputResponse(rslt);
        }).catch(function (err) {            
            console.warn(err);
            outputResponse(err, true);
        })

        return false;
    }

    useEffect(() => {
        console.log('search effect')
        //  setCount(cnt);
    }, [searchTerm]);

    useEffect(() => {
        console.log('RUNNING PAGE ONCE');
    }, []);

    var postUrl = IAB.dataLabel.getApiServer() + 'api/v1/audiences';


    function handleFileChange(evt){
        var ctl = evt.currentTarget;
//        setFileValue(ctl.files[0]);
    }


    return (
        <div className="pageContainer">

            <div className="leftBarGrid">
                <div className="leftSidebar">
<UploadSidebar />
                </div>
                <div className="mainGridCell">


<MyDropzone></MyDropzone>

                    <div>
                        <form
                            name="segmentUploadForm"
                            id="segupform"
                            method="post"
                            action={postUrl}
                            data-xonsubmit="return false"
                            encType="multipart/form-data"
                            onSubmit={uploadFile}
                        >
                            <div className="labelGroup">
                                <label className="control-label" >
                                    Audience CSV File
                                </label>{' '}
                                <input type="file" name="file" 
                                onChange={handleFileChange}
                                className="btn btn-success" id="dataFlatFile" />
                            </div>
                            <br />

                            <button type="submit" className="btn btn-warning iabRedBtn">
                                Upload and Test
                            </button>
                        </form>
                        <br />
                        <div className="card">
                <div className="card-body">
                    <div id="uploadResult">

                    </div>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                </div>
            </div>

                    </div>


                </div>
            </div>


        </div>


    );
};

export default withRouter(UploadLabelsPage);

