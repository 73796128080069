import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { green } from '@mui/material/colors';
import { withStyles } from '@mui/styles';

const CheckBox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export default CheckBox;
