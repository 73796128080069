export const renderCards = audiences => {

  var cards = [];
  try {
    cards.push(
      {
        cardTitle: 'I. Data Summary',
        cardInfo: [
          {
            label: 'Provider Name',
            fieldData: audiences.map(audience => audience.provider.name)
          },
          {
            label: 'Provider Domain',
            fieldData: audiences.map(audience => audience.provider.primaryDomain)
          },
          {
            label: 'Provider Email',
            fieldData: audiences.map(audience => audience.provider.contactEmail)
          },
          {
            label: 'Audience Name',
            fieldData: audiences.map(audience => audience.name)
          },
          {
            label: 'Provider Audience ID',
            fieldData: audiences.map(audience => audience.audienceKey)
          },
          {
            label: 'Standardized Audience ID(s)',
            fieldData: audiences.map(audience =>
              audience.taxonomies
                .map(({ condensedName }) => condensedName)
                .join(' > ')
            )
          },
          {
            label: 'Segmentation Criteria',
            fieldData: audiences.map(audience => audience.criteriaSummary)
          },
          {
            label: 'Audience Precision Level',
            fieldData: audiences.map(audience =>
              audience.audiencePrecisionLevels.map(({ name }) => name).join(', ')
            )
          },
          {
            label: 'Audience Scope',
            fieldData: audiences.map(audience => audience.audienceScope)
          },
          {
            label: 'Originating Domain',
            fieldData: audiences.map(audience => audience.originatingDomain)
          },
          {
            label: 'Audience Size',
            fieldData: audiences.map(audience => audience.audienceSize)
          },
          {
            label: 'ID Type(s)',
            fieldData: audiences.map(audience =>
              audience.audienceIdTypes.map(({ name }) => name).join(', ')
            )
          },
          {
            label: 'Privacy Compliance Mechanism(s) Used',
            fieldData: audiences.map(audience =>
              audience.privacyComplianceMechanisms.map(({ name }) => name).join(', ')
            )
          },
          {
            label: 'Data Provider Privacy Policy',
            fieldData: audiences.map(
              audience => audience.provider.privacyPolicyUrl
            )
          }
        ]
      });
  }
  catch (ex1) {
    console.warn(ex1);
  }

  // Section 2
  try {


    cards.push(
      {
        cardTitle: 'II. Audience Details',
        cardInfo: [
          {
            label: 'Data Sources',
            fieldData: audiences.map(audience =>
              audience.detailSource.map(({ name }) => name).join(', ')
            )
          },
          {
            label: 'Data Inclusion Methodology',
            fieldData: audiences.map(audience =>
              audience.inclusionMethodology.map(({ name }) => name).join(', ')
            )
          },
          {
            label: 'Audience Expansion',
            fieldData: audiences.map(
              audience => audience.valueMap.audienceExpansion
            )
          },
          {
            label: 'Cross-Device Expansion',
            fieldData: audiences.map(
              audience => audience.valueMap.crossDeviceExpansion
            )
          },
          {
            label: 'Audience Refresh Cadence',
            fieldData: audiences.map(audience => audience.valueMap.refreshCadence)
          },
          {
            label: 'Source Lookback Window',
            fieldData: audiences.map(audience => audience.valueMap.lookbackWindow)
          }
        ]
      });
  }
  catch (ex2) {
    console.warn(ex2);
  }


  // Section 3
  try {


    cards.push(
      {
        cardTitle: 'III. Onboarder Details',
        cardInfo: [
          {
            label: 'Input ID / Match Key',
            fieldData: audiences.map(audience =>
              audience.matchKeys.map(({ name }) => name).join(', ')
            )
          },
          {
            label: 'Audience Expansion',
            fieldData: audiences.map(
              audience => audience.valueMap.audienceExpansion
            )
          },
          {
            label: 'Cross Device Expansion',
            fieldData: audiences.map(
              audience => audience.valueMap.crossDeviceExpansion
            )
          },
          {
            label: 'Onboarder Audience Precision Level',
            fieldData: audiences.map(
              audience => audience.onboarderAudiencePrecision.name
            )
          }
        ]
      }

    );
  }
  catch (ex3) {
    console.warn(ex3);
  }

  return cards;
};
