import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import CompareButton from './compare';
import GroupBy from './group';
import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Search from './search';
import { HomeContext } from '../context';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    boxShadow: 'none',
    top: '64px',
    backgroundColor: '#343434'
  },
  toolbar: {
    minHeight: '56px!important',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    flexWrap: 'wrap'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  }
}));

const ToolBar = props => {
  const classes = useStyles();
  const { grouped, response, select } = useContext(HomeContext);
  // eslint-disable-next-line
  const [groups, setGroups] = grouped;
  const [responseData] = response;
  const [selected] = select;

  const handleSearch = e => {
    let query = e.target.value;
    const regex = new RegExp(query, 'i');
    let filteredGroups = responseData.filter(group => {
      let stringifiedData = JSON.stringify(group);
      if (stringifiedData.match(regex)) return group;
      else return null;
    });

    setGroups(filteredGroups);
  };

  const handleComparison = async () => {
    let labelIds = Object.keys(selected).map(label => {
      let id = label.split(' ');
      return id[id.length - 1];
    });

    try {
      props.history.push(`/labels/compare/${labelIds.join('-')}`);
    } catch (err) {
      console.log('Handle comparison Error:', err);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.grow}>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <CompareButton handleComparison={handleComparison} />

            <div className={classes.container}>
              <GroupBy />
              <Search handleSearch={handleSearch} />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ToolBar);
