import React, { useContext } from 'react';
import { CSVLink } from 'react-csv';
import styles from './index.module.scss';
import { ImportContext } from '../../context';

const Filetypes = () => {
  const { template } = useContext(ImportContext);
  return (
    <div className={styles.acceptedFormat}>
      <p>
        <a href="https://app-api.datalabel.org/api/v1/audience/samplecsv" rel="noreferrer"
        target="_blank">Download the sample csv</a> &nbsp;
        to review the format and use as a template to populate and uploade.
        Supported file types include .csv, .tsv, and .txt.
        </p>
    </div>
  );
};

export default Filetypes;
