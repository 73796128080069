import { Modal } from 'react-bootstrap';
import React, { useState, useContext } from 'react';
import { ImportContext } from '../../context';
import styles from './index.module.scss';
var IAB = window.IAB;

const LoadingSpinner = () => {
  return <div className={styles.ringIcon}></div>;
};

const DeleteModal = props => {
  const { notify } = useContext(ImportContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteAudiences = async () => {
    setLoading(true);
    try {
      await IAB.dataLabel.deleteMyAudiences();
      setTimeout(() => {
        handleClose();
        setLoading(false);
        notify(true, 'Successfully Deleted Audiences!');
      }, 4000);
    } catch (err) {
      console.log('Error deleting audiences', err);
      setLoading(false);
      notify(false, 'There was a problem deleting audiences');
    }
  };

  return (
    <>
      <button className={styles.deleteModalBtn} onClick={handleShow}>
        Delete all my uploaded audiences
      </button>

      <Modal show={show} onHide={handleClose} style={{ zIndex: 10000 }}>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={styles.deleteModalTitle}>
            Are you absolutely sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This action cannot be undone. This will permanently delete the
          audiences associated with your account.
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          {!loading && (
            <button
              className={styles.confirmDeleteBtn}
              onClick={handleDeleteAudiences}
            >
              I understand the consequences, delete all my uploaded audiences
            </button>
          )}
          {loading && (
            <button className={styles.deleteModalLoadingBtn} disabled={true}>
              <LoadingSpinner />
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
