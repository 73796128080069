import React from 'react';

let IAB = window.IAB || {};

class AudienceUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tax: props.taxonomy
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    console.log('clicked ' + this.state.tax.id);
    /*
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
    */
  }

  uploadFile(event){
    console.log('start upload');

    var frm = document.getElementById('segupform');
    IAB.dataLabel.uploadAudienceCsv({body: frm}).then(function(rslt){
      console.log(rslt);
    }).catch(function(err){
      console.warn(err);
    })

    event.preventDefault();
    return false;
  }

  render() {
    // var results = this.state.results || [];
    var postUrl = IAB.dataLabel.getApiServer() + 'api/v1/audiences';
    return (
      <div>
        <form
          name="segmentUploadForm"
          id="segupform"
          method="post"
          action={postUrl}
          data-xonsubmit="return false"
          encType="multipart/form-data"
          onSubmit={this.uploadFile}
        >
          <div className="labelGroup">
            <label className="control-label" >
              Audience CSV File
            </label>{' '}
            <input type="file" name="file" className="btn btn-success" id="dataFlatFile" />
          </div>

          <button type="submit" className="btn btn-warning iabRedBtn">
            Upload and Test
          </button>
        </form>
      </div>
    );
  }
  AudienceUploadForm;
}

export default AudienceUploadForm;
