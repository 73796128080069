import React, { useContext } from 'react';
import styles from './index.module.scss';
import Preview from '../preview';
import ActionButtons from '../form/actionbutton';
import { ImportContext } from '../../context';

const Response = props => {
  const { responses } = useContext(ImportContext);
  const [response] = responses;

  return (
    <div className={styles.responseContainer}>
      {response.success && <ActionButtons />}
      {!response.success && (
        <h3>
          There were some errors with your submission. Please correct them below
          and download the updated CSV and re-upload.
        </h3>
      )}
      {response && <Preview />}
    </div>
  );
};

export default Response;
