
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Link, NavLink } from 'react-router-dom';


/**
 * The navigation links
 * @param {} props 
 * @returns 
 */
function DatalabelNav(props){
    var isLoggedIn = props.isLoggedIn;
    var p = props.userPermissions || {};
    let loginBtn = '';
    let uploadBtn = '';
    let canUpload = false;
  
    if(isLoggedIn && p.checkDone && p.uploadPerm != null 
        && p.uploadPerm.status === 'granted'){
        canUpload = true;
    }
    if (isLoggedIn) {
        loginBtn = (
          <Nav.Link
            className="navLink navLogout"
            href="/log-out"
          >
            <IconButton className="iconButton">
              <ExitToAppIcon className="icon" />
            </IconButton>
          </Nav.Link>
        );
      }
    
      if (canUpload) {
        uploadBtn = (
          <NavLink className="navLink" to="/upload">
            <IconButton className="iconButton">
              <CloudUploadIcon className="icon" />
            </IconButton>
          </NavLink>
        );
      }
    

// 
      return (
        <Navbar collapseOnSelect expand="lg" className="siteHeaderNav">
          <NavLink className="plainLink" to="/">
        <Navbar.Brand >
          <img alt="" src="/images/check-min.png" style={{width: "40px", height: "40px"}} />{' '}
          <span style={{ color: 'white', fontSize: '1em', fontWeight: 'bold' }}>
            DataLabel
          </span>
        </Navbar.Brand>
        </NavLink>
        <Nav className="justify-content-end responsiveNav">
            {uploadBtn}
            {loginBtn}

        </Nav>
      </Navbar>
  
      )

}



/* ===================================================================================== */

/**
 * The main site header. This encapsulates the top navigation and page headers
 * @param {*} param0 
 * @returns 
 */
function SiteHeader(props){
    var isLoggedIn = props.isLoggedIn;
    var user = props.user || {};
    var firstname = user.firstname || 'Not Logged In';

    //  { user, isLoggedIn, userPermissions }
    const from = document.location.pathname;
    // N
    if( from === '/log-out' || from === '/login'){
        return '';
    }

  return(
      <div id="mainSiteHeader" className="siteHeader">
          <header>
          <DatalabelNav
      isLoggedIn={isLoggedIn}
      name={firstname}
      userPermissions={props.userPermissions}
    />
          </header>
      </div>
  );
};

export default SiteHeader;





