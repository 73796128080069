import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import { IconButton, Icon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



/**
 * Set CSS class on elem
 * @param {*} elem 
 * @param {*} css 
 * @param {*} state 
 */
 const setClass = function(elem, css, state){
  if(elem && elem.classList){
      if(state){
          elem.classList.add(css);
      }
      else{
          elem.classList.remove(css);
      }
  }
}

function ContentApiDocuments(props){

  return (
    <div>
      <ul className="listNoIndent cleanLinks">
        <li>
        <a href="https://app-api.datalabel.org/docs/oauth" rel="noreferrer"
    target="_blank">How to obtain and API Access Token (with live demo)</a>
        </li>
        <li>
        <a href="https://app-api.datalabel.org/docs/audience" rel="noreferrer"
    target="_blank">Audience flat file structure</a>
        </li>
        <li>
        <a href="/api/documentation" rel="noreferrer"
    target="_blank">List of APIs</a>
        </li>
      </ul>
    </div>
  );

  // /api/documentation

}


function ContentTemplateDownload(props){
  return (
    <div>
    <p>
    <a href="https://app-api.datalabel.org/api/v1/audience/samplecsv" rel="noreferrer"
    target="_blank">Download the sample csv</a> &nbsp;
    to review the format and use as a template to populate and uploade.
    Supported file types include .csv and .txt.
    </p>
    <div>Please note:<br/></div>
          <ul  className="listNoIndent">
            <li >
              Each field in the template corresponds to a field in the Data
              Transparency Standard 1.0. Please observe the “Field Options”
              and “Format Requirements” that need to be reflected in the
              upload template before successful upload.
            </li>
            <li >
              Any extraneous formatting such as hyperlinks, color, or
              columns are not supported.{' '}
            </li>
            <li >
              This template treats comma in text as a delimiter. As such, it
              is recommended to use a tab delimited .txt file format instead
              of a comma delimited .csv (to avoid the need to remove all
              commas if you’re copying from a pre-existing data dictionary).
              For example, if you were to include the following free form
              text within a comma delimited .csv cell – “This audience
              includes opt-in search data, survey data …” - all text after
              the “,” would be considered a separate entry within that
              field.
            </li>
          </ul>
      </div>

  )
}



/**
 * Sidebar wrapper component
 * @param {} props 
 * @returns 
 */
function UploadSidebar(props){

  const handleAccordionToggle = (groupDef, groupDomKey) => (event, isExpanded) => {
    var srcElem = event.currentTarget;
    var iconWrap = srcElem.querySelector('.stateIndicator');
    var header;
  
  }

  return (
    <div className="uploadSidebarWrap">
      <header>
        <h4>FAQ</h4>
      </header>

      <Accordion onChange={handleAccordionToggle(null, null)} >

        <AccordionSummary className="accordionHeader"
          expandIcon={<ExpandMoreIcon />} >
          <h5>API Documents and File Structure</h5>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
          <ContentApiDocuments />

        </AccordionDetails>
      </Accordion>
      <Accordion onChange={handleAccordionToggle(null, null)} >

        <AccordionSummary className="accordionHeader"
          expandIcon={<ExpandMoreIcon />} >
          <h5>Download CSV Template</h5>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
          <ContentTemplateDownload />

        </AccordionDetails>
      </Accordion>
      <Accordion onChange={handleAccordionToggle(null, null)} >

        <AccordionSummary className="accordionHeader"
          expandIcon={<ExpandMoreIcon />} >
          <h5>Data Delete and Replace</h5>
        </AccordionSummary>
        <AccordionDetails className="accordionContent">
          <p>
            By default your existing data will be overwritten with each upload.
            Use the gear icon to the right to delete data without upload.
          </p>
        </AccordionDetails>
      </Accordion>
    </div>

  );
}

  
  export {
    UploadSidebar
  }
  