import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from '../src/pages/home';
import AudienceHomePage from './pages/AudienceHomePage';
import UploadLabelsPage from './pages/UploadLabelsPage';
import Audience from './pages/audience';
import Import from '../src/pages/import';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Compare from '../src/pages/compare';
import ApiOverview from '../src/pages/apisample';
import TaxonomyDetail from '../src/pages/apisample/TaxonomyDetail';
import Swagger from '../src/pages/swagger';
import PrivacyPolicy from '../src/pages/privacy';
import { PrivateRoute } from './components/PrivateRoute';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const Routes = props => {
  console.log('Path: ' + props.location.pathname);
  ReactGA.pageview(props.location.pathname);

  return (
    <Switch>
      <Route exact path="/logout" component={Logout} {...props} />
      <Route exact path="/log-out" component={Logout} {...props} />
      <Route path="/login" component={Login} {...props} />
      <Route exact path="/apisample" component={ApiOverview} />
      <Route
        exact
        path="/apisample/taxonomy/:id"
        component={TaxonomyDetail}
        {...props}
      />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/api/documentation"
        component={Swagger}
        {...props}
      />
      <Redirect from="/api" to="/api/documentation" />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/audience/:id"
        component={Audience}
        {...props}
      />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/privacy-policy"
        component={PrivacyPolicy}
        {...props}
      />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/imports/add"
        component={Import}
        {...props}
      />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/upload"
        component={UploadLabelsPage}
        {...props}
      />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/labels/search"
        component={AudienceHomePage}
        {...props}
      />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/labels/compare/:ids"
        component={Compare}
        {...props}
      />
      <PrivateRoute
        authed={props.isLoggedIn}
        path="/"
        component={AudienceHomePage}
        {...props}
      />
    </Switch>
  );
};

export default withRouter(Routes);
