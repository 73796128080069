const IAB = window.IAB;
const columns = [
  {
    dataKey: 'compare',
    title: ''
  },
  { dataKey: 'providerName', title: '' },
  { dataKey: 'standardIdName', title: '' },
  { dataKey: 'compliant', title: 'Certified' },
  { dataKey: 'summaryName', title: 'Audience Name' },
  { dataKey: 'label', title: 'Label' }
];

const getGroupedAudiences = async grouping => {
  let groupedAudiences;
  if (grouping === 'providerName') {
    groupedAudiences = await IAB.dataLabel.audiencesGroupedByProvider();
  } else {
    groupedAudiences = await IAB.dataLabel.audiencesGroupedByTaxonomy();
  }

  return groupedAudiences;
};

const collapseInitGroupedAudiences = (groupedAudiences, grouping) => {
  let groups = Object.values(groupedAudiences);
  let expandedGroups = groups.reduce((acc, item) => {
    let key;
    if (grouping === 'providerName') key = item.company.name;
    else key = item.taxonomy.name;
    acc[key] = false;

    return acc;
  }, {});

  return expandedGroups;
};

const sortGroups = (groups, sortByGrouping) => {
  return groups.sort((a, b) => {
    let prev, next;

    if (sortByGrouping === 'providerName') {
      prev = a.company.name;
      next = b.company.name;
    } else {
      prev = a.taxonomy.name;
      next = b.taxonomy.name;
    }

    if (prev < next) return -1;
    if (prev > next) return 1;
    return 0;
  });
};

const getColumnData = (columns, grouping) => {
  return columns.filter(item => item.dataKey !== grouping);
};

export async function getGroupedData(grouping) {
  let groupedData = await getGroupedAudiences(grouping);
  let groups = Object.values(groupedData['result']);
  let expandedGroups = collapseInitGroupedAudiences(groups, grouping);
  let sortedGroups = await sortGroups(groups, grouping);
  let columnData = getColumnData(columns, grouping);
  return { sortedGroups, expandedGroups, columnData };
}
