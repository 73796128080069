import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ImportContext } from '../context';
import styles from './index.module.scss';
import SideDrawer from './drawer';
import Upload from './upload';

const Container = () => {
  const { getTemplate } = useContext(ImportContext);

  useEffect(() => {
    getTemplate();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.importContainer}>
        <SideDrawer />
        <Upload />
      </div>
    </div>
  );
};

export default withRouter(Container);
