import React from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';

let IAB = window.IAB || {};

class TaxonomyResultLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tax: props.taxonomy
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    console.log('clicked ' + this.state.tax.id);
    /*
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
    */
  }

  render() {
    var tax = this.state.tax;

    return (
      <div className="searchDetail">
        <div>
          <Link
            to={'/apisample/taxonomy/' + tax.id}
            className={styles.boldLink}
            data-taxid={tax.id}
          >
            {tax.condensedName}
          </Link>
        </div>
      </div>
    );
  }
}

function SearchResults(props) {
  let results = props.results;
  return results.map(tax => (
    <TaxonomyResultLine taxonomy={tax} key={'taxid_' + tax.id} />
  ));
}

let searchTimer = 0;

class TaxonomySearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      results: []
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  searchTaxonomy(name) {
    var me = this;
    var obj = { q: name };

    if (searchTimer !== 0) {
      window.clearTimeout(searchTimer);
    }

    searchTimer = window.setTimeout(function() {
      IAB.dataLabel.searchAudienceTaxonomy(obj).then(function(wrapper) {
        var data = wrapper.result;
        me.setState({ results: data });
        console.log(data);
      });
    }, 200);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleSearchChange(event) {
    var name = event.target.value;
    this.searchTaxonomy(name);
  }

  render() {
    var results = this.state.results || [];
    return (
      <div>
        <h1>Taxonomy Information</h1>
        <div>
          <label>Search Name</label>
          <input
            id="taxSearchName"
            type="text"
            onChange={this.handleSearchChange}
          />
        </div>
        <SearchResults results={results} />
      </div>
    );
  }
}

export default TaxonomySearch;
