import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toast } from './components/UiWidgetPack';
import 'react-toastify/dist/ReactToastify.css';
import SiteHeader from './layout/SiteHeader';
import Footer from './layout/Footer';
import Routes from './routes';
import ReactGA from 'react-ga';

// import the global styles
import './scss/App.scss';
import './scss/layout.scss';
import './scss/audiencePages.scss';

/**
 * @class DataLabel front end application.
 * Main React Application object. 
 * This wraps the entire application and coordinates user and state management.
 * It also controls the page URL routing.
 */
class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      from: document.location.pathname,
      user: this.props.user || null,
      isLoggedIn: (this.props.user || null) != null,
      userPermissions: { checkDone: false, accessPerm: null, uploadPerm: null }
    };

    // force https redirect in non-localhost environment
    var isDev = window.location.host.indexOf('local') > -1;
    if (!isDev && window.location.protocol.indexOf('https') === -1) {
      var fullUrl = window.location.href.replace('http://', 'https://');
      window.location = fullUrl;
      return;
    }

  }

  /**
   * Fires after init of components and determines if user is logged in.
   */
  async componentDidMount() {
    // GA4
    // G-F8N7DH13FX
    var GA_CODE = 'G-F8N7DH13FX';
    ReactGA.initialize(GA_CODE);
    let { isLoggedIn, user } = this.isUserLoggedIn();
    var permissionSet;
    try {
      permissionSet = await this.getUserPermissions();
    }
    catch (e) {
      console.warn('permission test error ', e);
      setTimeout(function () {
        document.location = 'https://tools.iabtechlab.com';
      }, 1800);
      return;
    }

    this.setState({
      userPermissions: permissionSet,
      isLoggedIn,
      user
    });

    if (permissionSet.checkDone && permissionSet.canView === false) {
      // redirect to Tools Site
      /*
      setTimeout(function() {
        document.location = 'https://tools.iabtechlab.com';
      }, 1800);
      var msg =
        '<h3>No permissions. Request access at IAB Tech Lab Tools Portal</h3><p>You are being redirected now</p>';
      document.write(msg);
      */
    }

    let from = document.location.pathname;

    if (!isLoggedIn) {
      let isLoginPage = from.indexOf('/login') === 0;
      if (!isLoginPage) {
        console.log('page is ... ', from)
        window.location = "/login"
        return;
      }
    }
  }

  /**
   * Checks the user login state
   * @returns User object or null
   */
  isUserLoggedIn() {
    var IAB = window.IAB;
    var user = IAB.getCurrentUser();

    var isLoggedIn = (user != null);

    if (!isLoggedIn) {
      const { from } = this.state;
      console.log('no defined user');
      let isLoginPage =
        from.indexOf('/login') === 0 && from.indexOf('/login-callback') === -1;
      if (!isLoginPage) {
        console.log('page is ... ', from);
        window.location = '/login';
      }
    }

    return { isLoggedIn, user };
  }

  /**
   * Obtain all the user permissions
   * @returns 
   */
  async getUserPermissions() {
    var IAB = window.IAB;
    //    var readProm = IAB.checkToolAccess({ toolCode: 'DATALABEL' });
    var uploadProm = IAB.checkToolAccess({ toolCode: 'DATALABEL_UPLOADER' });

    var ap, up;
    try {
      //      ap = await readProm;
      up = await uploadProm;
    }
    catch (e) {
      console.error("Caught staging cors error");
    }


    let result = {
      checkDone: true,
      accessPerm: ap,
      uploadPerm: up,
      canUpload: false,
      canView: true
    };

    if (up != null) {
      result.canUpload = up.canAccess || false;
    }
    result.canView = true;

    return result;
  }

  /**
   * Render output by coordinating with the Router
   * @returns 
   */
  render() {
    const { user, isLoggedIn, userPermissions } = this.state;

    console.log("App render");
    console.log(window.location.pathname);

    return (
      <React.Fragment>
        <Router>
        <SiteHeader
          user={user}
          isLoggedIn={isLoggedIn}
          userPermissions={userPermissions}
        />
          <div className="appPageWrapper">
            <Routes userPermissions={userPermissions} isLoggedIn={isLoggedIn} />
          </div>
        <Toast />
        <Footer />
        </Router>

      </React.Fragment>
    );
  }
}

export default App;
