import React, { useState, useContext, useEffect } from 'react';
import { ImportContext } from '../../context';
import PreviewTable from './table';
import styles from './index.module.scss';

const Preview = props => {
  const [rows, setRows] = useState(null);
  const [columns, setCols] = useState(null);
  const [errorLines, setErrorLines] = useState(null);
  const { files, responses } = useContext(ImportContext);
  const [response] = responses;
  const [myFiles] = files;

  useEffect(() => {
    handlePreview();
    // eslint-disable-next-line
  }, [myFiles]);

  const isErrorUploading = () => {
    if (response.hasOwnProperty('error')) {
      return true;
    } else {
      return false;
    }
  };

  const getErrorLines = () => {
    let errorLines = response.info.reduce(
      (errorAccum, currentError, currentErrorIndex) => {
        let { val } = currentError;
        let splitMessage = response.messages[currentErrorIndex].split(' ');
        let errorLine = splitMessage[splitMessage.length - 1] - 1;
        errorAccum[errorLine] = val;
        return errorAccum;
      },
      {}
    );

    return errorLines;
  };

  const buildRows = (readFile, errorLines) => {
    let rows = readFile;

    rows.forEach((row, index) => {
      if (errorLines.hasOwnProperty(index)) {
        row.hasError = true;
        row.errorLine = index;
      } else {
        row.errorLine = -1;
        row.hasError = false;
      }
    });

    return sortRows(rows);
  };

  const sortRows = rows => {
    return rows.sort((currRow, nextRow) => {
      return currRow.hasError === nextRow.hasError
        ? 0
        : currRow.hasError
        ? -1
        : 1;
    });
  };

  const handlePreview = () => {
    let { readFile } = myFiles[0];

    if (isErrorUploading()) {
      return null;
    }

    let errorLines = getErrorLines();
    let rows = buildRows(readFile, errorLines);
    let columns = Object.keys(readFile[0]).filter(col => {
      if (col !== 'hasError' && col !== 'errorLine') {
        return col;
      } else {
        return '';
      }
    });

    setErrorLines(errorLines);
    setCols(columns);
    setRows(rows);
  };

  const updateRowsAfterDownload = updatedRows => {
    setRows(updatedRows);
  };

  const preview =
    rows && columns ? (
      <PreviewTable
        rows={rows}
        columns={columns}
        updateRowsAfterDownload={updateRowsAfterDownload}
        errorLines={errorLines}
      />
    ) : (
      response.message
    );

  return <div className={styles.previewContainer}>{preview}</div>;
};

export default Preview;
