import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Mobile from './MobileContent';
import Desktop from './DesktopContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getCSV } from '../../components/dataHelpers';

function Compare(props) {
  const isDesktop = useMediaQuery('(min-width: 970px)');
  const [selectedAudiences, setSelectedAudiences] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadableCSV, setDownloadableCSV] = useState([]);
  const IAB = window.IAB;

  useEffect(() => {
    const fetchAudiencesToCompare = async () => {
      try {
        const { ids } = props.match.params;
        const selectedAudienceIds = ids.split('-').map(id => parseInt(id));

        const result = await IAB.dataLabel.compareAudiences(selectedAudienceIds);
        const csvToDownload = await getCSV(ids);

        var data = [];
        if (result.success) {
          data = result.result;
        }
        setDownloadableCSV(csvToDownload);
        setSelectedAudiences(data);
        setIsLoading(false);
      } catch (err) {
        console.log('Error', err);
      }
    };

    fetchAudiencesToCompare();
  }, [IAB.dataLabel, props.match.params]);

  var content;
  if(isDesktop){
    content = (<Desktop
      audiences={selectedAudiences}
      isLoading={isLoading}
      downloadableCSV={downloadableCSV}
    />);
  }
  else{
    content = (
      <Mobile
      audiences={selectedAudiences}
        isLoading={isLoading}
        downloadableCSV={downloadableCSV}
      />
    );
  }

  return (
    <div className="audienceContainer">
      {content}
    </div>
  );
};

export default withRouter(Compare);
