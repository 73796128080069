import React from 'react';
import Table from './table';
import { withRouter } from 'react-router-dom';
import { HomeProvider } from './context';

const Home = () => (
  <HomeProvider>
    <Table />
  </HomeProvider>
);

export default withRouter(Home);
