import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    },
    marginLeft: 0,
    width: '100%'
  },
  searchIcon: {
    width: '56px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: "'Open Sans', sans-serif"
  },
  inputInput: {
    padding: '8px 8px 8px 56px',
    transition: 'width 1s',
    width: '100%'
  }
}));

const Search = ({ handleSearch }) => {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search labels…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch}
      />
    </div>
  );
};

export default Search;
