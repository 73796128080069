import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';
import AudienceCard from '../audience/AudienceCard';
import { renderCards } from '../audience/utils';
import uuid from 'uuid';
import { DownloadCSV } from '../../components/UiWidgetPack';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: '0' }}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}


function Mobile(props) {
  const [value, setValue] = useState(0);
  const { selectedAudiences, isLoading, downloadableCSV } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="mobile">
      <AppBar position="sticky" className="appbar appbarRoot">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="indicator"
        >
          {selectedAudiences &&
            selectedAudiences.map((audience, index) => (
              <Tab label={audience.name} {...a11yProps(index)} key={uuid.v4()} />
            ))}
        </Tabs>
      </AppBar>
      {selectedAudiences &&
        selectedAudiences.map((audience, index) => (
          <TabPanel value={value} index={index} key={uuid.v4()}>
            <DownloadCSV downloadableCSV={downloadableCSV} />
            {renderCards([audience]).map(
              ({ audienceCardTitle, audienceCardInfo }) => (
                <AudienceCard
                  isLoading={isLoading}
                  audienceCardTitle={audienceCardTitle}
                  audienceCardInfo={audienceCardInfo}
                  key={uuid.v4()}
                />
              )
            )}
          </TabPanel>
        ))}
    </div>
  );
};

export default withRouter(Mobile);
