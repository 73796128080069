import React from 'react';

function TaxonomySimple(props) {
  var tax = props.taxonomy;
  return (
    <div className="taxonomyDetail">
      <div>
        <label>Standard Taxonomy Name </label>
        <button data-taxid={tax.id}>
          <b>{tax.condensedName}</b>
        </button>
      </div>
    </div>
  );
}

export default TaxonomySimple;
