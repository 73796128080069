import React from 'react';
import { withRouter } from 'react-router-dom';
import AudienceCard from '../audience/AudienceCard';
import Typography from '@mui/material/Typography';
import uuid from 'uuid';
import { renderCards } from '../audience/utils';
import { DownloadCSV } from '../../components/UiWidgetPack';
import { DataLabelAppBar } from '../../components/UiWidgetPack';


function Desktop(props) {
  const { isLoading, audiences, downloadableCSV } = props;
  return (
    <div className="desktop">
      <DataLabelAppBar>
        <div className="headerRow">
          {audiences && (
            <>
              <Typography
                className="appbarHeader"
                style={{ width: 200, overflow: 'visible' }}
              >
                <DownloadCSV downloadableCSV={downloadableCSV} />
              </Typography>
              {audiences.map(aud => (
                <Typography className="appbarHeader" key={uuid.v4()}>
                  {aud.name}
                </Typography>
              ))}
            </>
          )}
        </div>
      </DataLabelAppBar>
      {audiences &&
        renderCards(
          audiences
        ).map(({ cardTitle, cardInfo }) => (
          <AudienceCard
            isLoading={isLoading}
            cardTitle={cardTitle}
            cardInfo={cardInfo}
            key={uuid.v4()}
          />
        ))}
    </div>
  );
};

export default withRouter(Desktop);
