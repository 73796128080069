import React, { useContext } from 'react';
import Dropzone from './dropzone';
import styles from './index.module.scss';
import { ImportContext } from '../../context';
import FileList from './filelist';

const UploadForm = () => {
  const { files } = useContext(ImportContext);
  const [myFiles] = files;
  const showFile = myFiles.length > 0 ? <FileList /> : '';

  return (
    <div className={styles.importForm}>
      <section className="container">
        {showFile}
        <Dropzone style={{ display: myFiles.length > 0 && 'none' }} />
      </section>
    </div>
  );
};

export default UploadForm;
