import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import styles from './index.module.scss';
import { ImportContext } from '../../../context';
import uuid from 'uuid';
import Progress from '../progress';

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: 'pointer',
    marginLeft: '.2em',
    '&:hover': {
      color: 'green'
    }
  },
  ul: {
    padding: '1em',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
}));

const Filelist = props => {
  const classes = useStyles();
  const { files, removeFile } = useContext(ImportContext);
  const [myFiles] = files;
  const fileList = myFiles.map(file => {
    let { rawFile } = file;

    return (
      <li key={uuid.v4()} className={styles.fileContainer}>
        <div className={styles.fileInfo}>
          <div className={styles.fileItem}>
            {rawFile.name.slice(0, rawFile.name.length - 4)}
          </div>
        </div>
        <Progress />
        <CloseIcon onClick={removeFile} className={classes.icon} />
      </li>
    );
  });

  return (
    myFiles.length > 0 && (
      <aside>
        <ul className={classes.ul}>{fileList}</ul>
      </aside>
    )
  );
};

export default Filelist;
