import React, { useContext, useEffect } from 'react';
import { Table, TableContainer, Paper } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { LoadIcon } from '../../../components/UiWidgetPack';
import { makeStyles } from '@mui/styles';
import ToolBar from '../toolbar';
import Tablebody from './body';
import Tablehead from './head';
import { HomeContext } from '../context';

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: '0px',
    boxShadow:
      '0px 3px 10px -1px rgb(132, 130, 130), 0px 1px 1px 0px rgb(151, 150, 150), 0px 1px 3px 0px rgb(218, 218, 218)'
  },
  container: {
    maxHeight: 600
  }
});

const GroupedTable = () => {
  const classes = useStyles();
  const { loading, fetchGroupedAudiences } = useContext(HomeContext);
  const [isLoading] = loading;

  useEffect(() => {
    fetchGroupedAudiences('providerName');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.root}>
      <ToolBar />
      {isLoading ? (
        <LoadIcon
          loadingMessage={''}
          customStyle={{
            height: '400px',
            paddingTop: '150px'
          }}
        />
      ) : (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <Tablehead />
            <Tablebody />
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default withRouter(GroupedTable);
