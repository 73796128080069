import { least } from "d3-array";



const IAB = window.IAB;

async function allAudiences(pageNum, pageSize){
  var opts = {
    pagenum: pageNum,
    ps : pageSize
  }
  var data = await IAB.dataLabel.audienceListFull(opts);
  if(data.success){
    data.result.map(a => { initSearchBlob(a); return true;});
  }

  return data;
}

/**
 * Constructs the "searchBlob" field for keywork searches
 * @param {Audience object} audience 
 */
function initSearchBlob(audience){
  var a = audience;
  var sb = [];
  sb.push(a.name);
  sb.push(a.criteriaSummary);
  sb.push( (a.originatingDomain || '') );
  var i, t;
  if(Array.isArray(a.taxonomies)){
    for(i=0;i < a.taxonomies.length;i++){
      t = a.taxonomies[i];
      sb.push(t.name);
      sb.push(t.condensedName);
    }
  }

  a.searchBlob = (sb.join(' ')).toLowerCase();

}

var grouperKeys = {
  byProvider: function(audience){
    var result = {
      keys: [],
      groups: []
    }
    result.groups.push(audience.provider);
    result.keys.push(result.group.primaryDomain || null);
    return result;
  },
  byTaxonomy: function(audience){
    var i, t;
    var result = {
      keys: [],
      groups: []
    }
    let tax = audience.taxonomies;

    for(i=0;i<tax.length;i++){
      t = tax[i];
      result.keys.push(t.id);
      result.groups.push(t);
    }

    return result;
  }

}

/**
 * Transform the list into an object with an array of keys and a dictionary of groups and audiences
 * @param {string} groupKey Key to group on
 * @param {Array} list Raw list of Audience objects to group
 */
function groupAudienceList(list, groupKey){
  let result = {
    groupKey: groupKey,
    groups: {

    }
  }
  var gfunc;
  var i, n, a, keyObj, k;
  var gdata, g;
  var grp = result.groups;

  if(groupKey === 'taxonomy'){
    gfunc = grouperKeys.byTaxonomy;
  }

  for(i=0;i<list.length;i++){
    a = list[i];
    if(groupKey === 'provider'){
      keyObj = a.provider;
      if(keyObj == null){
        keyObj = {
          name: 'Unknown',
          primaryDomain: 'example.org'
        }
      }
      k = keyObj.primaryDomain;
      if(grp[k] == null){
        grp[k] = {
          group: Object.assign({}, keyObj),
          items: []
        }
      }
      grp[k].items.push(a);
    }
    else if(groupKey === 'taxonomy'){
      gdata = gfunc.call(null, a);
      for(n=0;n<gdata.groups.length;n++){
        keyObj = gdata.groups[n];
        k = keyObj.id;
        if(grp[k] == null){
          grp[k] = {
            group: Object.assign({}, keyObj),
            items: []
          }
        }
        grp[k].items.push(a);
      }
    }
  }

  //sort results.
  const nameSort = function(a, b){
    if(a == null || b == null){
      return 0;
    }
    let aval = a.group.name.toLowerCase().charCodeAt(0);
    let bval = b.group.name.toLowerCase().charCodeAt(0);
    if(aval === bval){
      return 0;
    }
    else if(aval < bval){
      return -1;
    }
    return 1;
  }

  result.groupArray = Object.values(result.groups);
  result.groupArray.sort(nameSort);

  return result;
}





// V1.0 code
const columns = [
  {
    dataKey: 'compare',
    title: ''
  },
  { dataKey: 'providerName', title: '' },
  { dataKey: 'standardIdName', title: '' },
  { dataKey: 'compliant', title: 'Certified' },
  { dataKey: 'summaryName', title: 'Audience Name' },
  { dataKey: 'label', title: 'Label' }
];

const getColumnData = (columns, grouping) => {
  return columns.filter(item => item.dataKey !== grouping);
};

const getGroupedAudiences = async grouping => {
  let groupedAudiences;
  if (grouping === 'providerName') {
      groupedAudiences = await IAB.dataLabel.audiencesGroupedByProvider();
  } else {
      groupedAudiences = await IAB.dataLabel.audiencesGroupedByTaxonomy();
  }

  return groupedAudiences;
};



const collapseInitGroupedAudiences = (groupedAudiences, grouping) => {
  let groups = Object.values(groupedAudiences);
  let expandedGroups = groups.reduce((acc, item) => {
    let key;
    if (grouping === 'providerName') key = item.company.name;
    else key = item.taxonomy.name;
    acc[key] = false;

    return acc;
  }, {});

  return expandedGroups;
};


async function getGroupedData(grouping) {
  let groupedData = await getGroupedAudiences(grouping);
  let groups = Object.values(groupedData['result']);
  let expandedGroups = collapseInitGroupedAudiences(groups, grouping);
  let sortedGroups = await sortGroupsZ(groups, grouping);
  let columnData = getColumnData(columns, grouping);
  return { sortedGroups, expandedGroups, columnData };
}

const sortGroupsZ = (groups, sortByGrouping) => {
  return groups.sort((a, b) => {
    let prev, next;

    if (sortByGrouping === 'providerName') {
      prev = a.company.name;
      next = b.company.name;
    } else {
      prev = a.taxonomy.name;
      next = b.taxonomy.name;
    }

    if (prev < next) return -1;
    if (prev > next) return 1;
    return 0;
  });
};
// end v1.0 code    



// ============== Create export object 

const AudienceService = {
    getGroupedData : getGroupedData,
    /**
     * Group a flat audience list based on key
     */
    groupAudienceList: groupAudienceList,

    allAudiences: allAudiences
}

export default AudienceService;

