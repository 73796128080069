import React, { useState, createContext } from 'react';
import styles from './index.module.scss';
const { getGroupedData } = require('./utils');

export const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
  const [expandedGroups, setExpandedGroups] = useState({});
  const [groups, setGroups] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [selectedLength, setSelectedLength] = useState(0);
  const fetchGroupedAudiences = async grouping => {
    setIsLoading(true);
    let res = await getGroupedData(grouping);
    setGroups(res.sortedGroups);
    setResponseData(res.sortedGroups);
    setExpandedGroups(res.expandedGroups);
    setIsLoading(false);
    setColumnData(res.columnData);
  };

  return (
    <div className={styles.homeContainer}>
      <HomeContext.Provider
        value={{
          expansion: [expandedGroups, setExpandedGroups],
          grouped: [groups, setGroups],
          response: [responseData, setResponseData],
          columns: [columnData, setColumnData],
          loading: [isLoading, setIsLoading],
          select: [selected, setSelected],
          length: [selectedLength, setSelectedLength],
          fetchGroupedAudiences
        }}
      >
        {children}
      </HomeContext.Provider>
    </div>
  );
};
