import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { ImportContext } from '../../../context';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Progress = props => {
  const classes = useStyles();
  const { responses, completion } = useContext(ImportContext);
  const [response] = responses;
  const [completed, setCompleted] = completion;

  useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 100;
        }
        const diff = 1;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    if (response) {
      const timer = setInterval(progress, 1);
      return () => {
        clearInterval(timer);
      };
    }
  }, [response, setCompleted, completed]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={completed} />
    </div>
  );
};

export default Progress;
