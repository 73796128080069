import React from 'react';

function showLoginFrame(){
    let IAB = window.IAB || {}

    if(IAB.hasAuthKey()){
      IAB.clearAuthorizeToken();
      IAB.logout(function(){ console.log('cleared')}, { no_popup: true});
    };

  setTimeout(function(){
//    debugger;
    IAB.authorize({
      cb: '/login-callback.html',
      authorized: initForAuthenticated,
      loginTarget: '#loginIframe'
    });
  }, 300);
}


function initForAuthenticated(user){
  let IAB = window.IAB || {}

  if(IAB.hasAuthKey()){
    user = IAB.checkAuthorized();
    window.top.location = "/";
  };

  /*
  var el = getEl('currentStatus');
  el.innerHTML = user.firstname;
  getEl('loginControls').style.display='none';
*/
  // loadToolsInfo();
  //

  // Get authorization for current tool. The applicationKey set is filled in for toolCode
  /*
  IAB.canAccessTool({
    success: function(result){
      var el = '';
      el.innerHTML += ' <b> Access cleared for tool (' + IAB.applicationKey + ')? ' + result + '</b>';
    }
  });
  */

}




class Login extends React.Component {


  render() {
    let IAB = window.IAB || {}


    var user = IAB.checkAuthorized();
    var clearUser = false;
    if(user != null){
      var msg = "You appear to be already logged in.\n Press OK to end your current session and re-enter credentials, \n or Cancel to return to Dashboard."
      clearUser = window.confirm(msg);
      if(clearUser){
        showLoginFrame();
      }
      else{
        setTimeout(function(){
          window.location = '/';
        }, 150);
        return <div>Redirecting...</div>;
      }

    }
    else{
      showLoginFrame();
    }

    return (
  <div id="outerLoginWrap">
      <div className="mx-auto container">
        <br/><br/>
        <div id="loginHeader" className="loginHeader center" >
          <div className="logoWrap"><img src="/images/iab-logo-tl-red.png" 
          style={{width:'85px', height:'auto'}}
          alt="IAB Tech Lab Logo" /></div>
          <div className="center">
          <h2>Tools Portal Login</h2>
          </div>
        </div>
      </div>
      <div id="loginFrameWrap" className="loginFrameWrap mx-auto" >
      <iframe src="data:text/html;charset=utf-8,Login to IAB Tech Lab" title="IAB Tech Lab SSO Login" id="loginIframe"  ></iframe>
      </div>
  </div>
    );
  }
}

export default Login
