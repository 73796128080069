import React from 'react';

const Footer = props => (
  <footer className="mainContentFooter content">
      <div className="footerContent footerLinks ">
        <a
          href="https://iabtechlab.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          About IAB Tech Lab
        </a>
        <a href="https://iabtechlab.com/privacy-policy/" target="_blank" rel="noopener noreferrer" >
          Privacy Policy
        </a>
        <a href="https://iabtechlab.com/terms-of-use/" target="_blank" rel="noopener noreferrer" >
          Terms of Use</a>
      </div>
      <div className="copyrightText  ">
        &copy; 2020-2021 IAB Tech Lab. All rights reserved.
      </div>

  </footer>
);

export default Footer;
