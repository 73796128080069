import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '../../../assets/images/cloud-computing.svg';
import styles from './index.module.scss';
import { ImportContext } from '../../../context';

const Dropzone = ({ style }) => {
  const { onDrop } = useContext(ImportContext);

  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    accept: '.csv, .tsv, .txt',
    onDrop
  });

  return (
    <form
      {...getRootProps({
        className: `${styles.inputContainer} dropzone`
      })}
      encType="multipart/form-data"
      id="form-upload"
      style={style}
    >
      <input {...getInputProps()} type="file" name="file" />

      <input type="hidden" name="keepPrevious" value="false" />

      <img
        src={uploadIcon}
        alt="Upload icon"
        style={{ minWidth: 100, maxWidth: 100 }}
      />
    </form>
  );
};

export default Dropzone;
