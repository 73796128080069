import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: 200
    }
  }
}));

const ValidationTextField = ({
  value,
  errorLines,
  rowNumber,
  colName,
  handleUpdatedRows,
  originalRowErrorNumber
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    currVal: value,
    isInvalid: false,
    currErrorMessage: '',
    currPlaceholder: ''
  });
  const errorCodes = {
    audience_criteria: 'criteriasummary',
    audience_name: 'name',
    audience_key: 'audiencekey',
    provider_name: [
      'Must specify Provider Name',
      'Provider name length error',
      'No Provider Name or ID submitted'
    ],
    geocode_list: 'You must specify at least one geo zone [ISO-3166-1-alpha-3]',
    taxonomy_id_list:
      'At least on Standard Audience Taxonomy value must be included.'
  };

  useEffect(() => {
    initErrorMessages();
    // eslint-disable-next-line
  }, []);

  const handleValidationCheck = val => {
    if (
      colName === 'audience_key' ||
      colName === 'audience_criteria' ||
      colName === 'audience_name'
    ) {
      if (val.length > 0) {
        setState({ ...state, isInvalid: false });
      } else {
        setState({
          ...state,
          currErrorMessage: 'Required field',
          isInvalid: true
        });
      }
    }

    if (colName === 'taxonomy_id_list') {
      if (val.length > 0) {
        setState({ ...state, isInvalid: false });
      } else {
        setState({
          ...state,
          currErrorMessage: 'Minimum 1 numeric ID (e.g. 634)',
          placeholder: 'e.g. 634;123;780',
          isInvalid: true
        });
      }
    }

    if (colName === 'provider_name') {
      if (val.length > 0 && val.length <= 100) {
        setState({ ...state, isInvalid: false });
      } else if (val.length === 0) {
        setState({
          ...state,
          currErrorMessage: 'Required field',
          isInvalid: true
        });
      } else if (val.length > 100) {
        setState({
          ...state,
          currErrorMessage: 'Exceeded max 100 characters',
          isInvalid: true
        });
      }
    }

    if (colName === 'geocode_list') {
      if (val.length > 0) {
        setState({ ...state, isInvalid: false });
      } else {
        setState({
          ...state,
          currErrorMessage: 'Minimum 1 three-letter country code (e.g. USA)',
          placeholder: 'e.g. USA;MEX',
          isInvalid: true
        });
      }
    }
  };

  const handleChange = e => {
    let val = e.target.value;
    setState({ ...state, currVal: val });
    handleUpdatedRows(rowNumber, colName, val);
    handleValidationCheck(val);
  };

  const initErrorMessages = () => {
    let isInvalid = false;
    let errorMessage = '';
    let placeholder = '';

    if (errorLines.hasOwnProperty(originalRowErrorNumber)) {
      let isProvider = Array.isArray(errorCodes[colName]);

      if (isProvider) {
        for (let errorCode of errorCodes[colName]) {
          if (errorLines[originalRowErrorNumber].includes(errorCode)) {
            isInvalid = true;
            if (errorCode === 'Provider name length error') {
              errorMessage = 'Exceeded max 100 characters';
            } else {
              errorMessage = errorCode;
            }
          }
        }
      } else {
        if (errorLines[originalRowErrorNumber].includes(errorCodes[colName])) {
          isInvalid = true;
          let message = errorCodes[colName];

          if (
            message === 'criteriasummary' ||
            message === 'audiencekey' ||
            message === 'name'
          ) {
            errorMessage = 'Required field';
          } else if (
            message ===
            'At least on Standard Audience Taxonomy value must be included.'
          ) {
            errorMessage = 'Minimum 1 numeric ID (e.g. 634)';
            placeholder = 'e.g. 634;123;780';
          } else if (
            message ===
            'You must specify at least one geo zone [ISO-3166-1-alpha-3]'
          ) {
            errorMessage = 'Minimum 1 three-letter country code (e.g. USA)';
            placeholder = 'e.g. USA;MEX';
          } else {
            errorMessage = errorCodes[colName];
          }
        }
      }
    }

    setState({
      isInvalid,
      currErrorMessage: errorMessage,
      currPlaceholder: placeholder
    });
  };

  return (
    <div className={classes.root}>
      <TextField
        error={state.isInvalid}
        helperText={state.isInvalid && state.currErrorMessage}
        placeholder={state.currPlaceholder}
        onChange={handleChange}
        value={state.currVal}
      />
    </div>
  );
};

export default ValidationTextField;
