import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const Subheader = ({ title, isLoading }) => {
  return (
    <header >
      {isLoading ? (
        <div >
          <Skeleton variant="text" width={300} />
        </div>
      ) : (
        title
      )}
    </header>
  );
};

export default Subheader;
