import React from 'react';
import { withRouter } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import styles from './index.module.scss';

var IAB = window.IAB;
// OpenAPI descriptor
var apiFile = IAB.dataLabel.getApiServer() +  "openapi/datalabelapi.v1.1.json"

const Swagger = props => (
  <div className={styles.docsContainer}>
    <SwaggerUI
    url={apiFile}    />
  </div>
);

export default withRouter(Swagger);
