import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dropdown } from 'react-bootstrap';
import DeleteModal from '../delete';
import styles from './index.module.scss';

const DropDown = props => (
  <div className={styles.settingsDropdown}>
    <Dropdown>
      <Dropdown.Toggle bsPrefix={styles.settingsDropdownToggleBtn}>
        <SettingsIcon />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Header>Danger Zone</Dropdown.Header>
        <Dropdown.Divider />
        <DeleteModal />
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default DropDown;
