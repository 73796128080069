import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { ImportContext } from '../../../context';
import styles from './index.module.scss';

const ActionButtons = props => {
  const { handleSubmit, removeFile, responses } = useContext(ImportContext);
  const [response] = responses;

  return (
    <div className={styles.buttonContainer}>
      <Button
        variant="contained"
        disableElevation
        disabled={response && !response.success}
        className={
          response && !response.success
            ? styles.disabledButton
            : styles.submitButton
        }
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <Button
        className={styles.cancelButton}
        variant="outlined"
        disableElevation
        onClick={removeFile}
      >
        Cancel
      </Button>
    </div>
  );
};

export default ActionButtons;
