import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.scss';

const PrivacyPolicy = props => {
  return (
    <div className={styles.privacyContainer}>
      <header>Policy</header>
      <main>
        <p>
          In using and evaluating this website (the “Website”), you agree to
          provide IAB Technology Laboratory, Inc. (“Tech Lab”) with feedback,
          comments, suggestions, ideas, and the like (“Feedback”). You agree
          that Tech Lab shall be free to use, reproduce, disclose, and otherwise
          exploit any and all such Feedback without compensation or attribution
          to you.
        </p>
        <p>
          YOU ACKNOWLEDGE THAT THE WEBSITE IS IN BETA, AND THEREFORE THE WEBSITE
          IS PROVIDED “AS IS” AND “AS AVAILABLE.” TECH LAB MAKES NO, AND HEREBY
          DISCLAIMS ALL, EXPRESS, IMPLIED, OR STATUTORY WARRANTIES OF ANY KIND
          WITH RESPECT TO THE WEBSITE AND THE DATA CONTAINED THEREIN OR DERIVED
          THEREFROM, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES WITH REGARD
          TO PERFORMANCE, MERCHANTABILITY, SATISFACTORY QUALITY,
          NON-INFRINGEMENT, FITNESS FOR ANY PARTICULAR PURPOSE, OR ANY
          WARRANTIES ARISING FROM A COURSE OF DEALING, COURSE OF PERFORMANCE, OR
          USAGE OF TRADE. TO THE EXTENT THAT TECH LAB MAY NOT AS A MATTER OF
          APPLICABLE LAW DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE AND DURATION
          OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED UNDER SUCH LAW.
        </p>
        <p>
          IN CONNECTION WITH YOUR ACCESS TO, OR USAGE OF, THE WEBSITE: (I) IN NO
          EVENT WILL TECH LAB BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY
          INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE
          DAMAGES OF ANY KIND REGARDLESS OF WHETHER TECH LAB WAS ADVISED, HAD
          OTHER REASON TO KNOW, OR IN FACT KNEW OF THE POSSIBILITY THEREOF; AND
          (II) TECH LAB’S AGGREGATE LIABILITY FOR DIRECT DAMAGES WILL NOT EXCEED
          ONE HUNDRED DOLLARS ($100).
        </p>
      </main>
    </div>
  );
};

export default withRouter(PrivacyPolicy);
