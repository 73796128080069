import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

var IAB = window.IAB;
var user = IAB.getCurrentUser();
var document = window.document;

ReactDOM.render(
  /*
  <React.StrictMode>
    <App user={user}  />
  </React.StrictMode>,
  */
  <App user={user}  />,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
