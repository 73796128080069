import React, { useEffect } from 'react';
import {LoadIcon} from '../components/UiWidgetPack';

const Logout = () => {
  useEffect(() => {
    const logoutUser = async () => {
      try {
        var IAB = window.IAB;
        if (IAB.hasAuthKey()) {
          await IAB.logout(
            function() {
              document.location = '/login';
            },
            { no_popup: true }
          );
        } else {
          await IAB.clearAuthorizeToken();
          document.location = '/login';
          // force reload
          setTimeout(function() {
            document.location = '/logout';
          }, 700);
        }
      } catch (err) {
        console.log('Error logging out', err);
      }
    };

    logoutUser();
  }, []);

  return (
    <div className="foo">
      <LoadIcon loadingMessage={'Logging Out'} />
    </div>
  );
};

export default Logout;
