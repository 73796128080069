import React, { useState, useContext } from 'react';
import { HomeContext } from '../../context';
import uuid from 'uuid';

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from '@mui/material';

const styles = {
  tableHeadCell: {
    background: '#ededed',
    fontFamily: "'Open Sans', sans-serif"
  }
};

const Tablehead = () => {
  const [sortBy, setSortby] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const { grouped, columns } = useContext(HomeContext);
  const [columnData] = columns;
  const [groups, setGroups] = grouped;

  const getSorting = order => {
    return (a, b) => {
      let label1, label2;
      label1 = a.name;
      label2 = b.name;

      return order === 'desc'
        ? label1 > label2
          ? -1
          : 1
        : label1 < label2
        ? -1
        : 1;
    };
  };

  const handleRequestSort = property => {
    let updateSortOrder = 'desc';
    if (sortBy === property && sortOrder === 'desc') {
      updateSortOrder = 'asc';
    }

    let sortedGroups = [...groups].map(row => {
      row.audiences = row.audiences.sort(getSorting(updateSortOrder, property));
      return row;
    });

    setGroups(sortedGroups);
    setSortOrder(updateSortOrder);
    setSortby(property);
  };

  return (
    <TableHead>
      <TableRow>
        {columnData.map((item, index) => (
          <TableCell style={styles.tableHeadCell} key={uuid.v4()}>
            {index === 3 ? (
              <TableSortLabel
                active={sortBy === item.dataKey}
                direction={sortOrder}
                onClick={handleRequestSort.bind(null, item.dataKey)}
              >
                {item.title}
              </TableSortLabel>
            ) : (
              item.title
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default Tablehead;
