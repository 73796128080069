import React, { useState } from 'react';
import Filetypes from '../filetypes';
import styles from './index.module.scss';

const SideDrawer = props => {
  const [showInstruction, setShowInstruction] = useState({
    download: true,
    upload: false,
    delete: false
  });

  const handleClick = instruction => {
    let isOpen = !showInstruction[instruction];
    setShowInstruction({ ...showInstruction, [instruction]: isOpen });
  };

  return (
    <nav className={styles.sideDrawer}>
      <header>
        <h4>FAQ</h4>
      </header>
      <ul className={styles.sideDrawerCollapsibleItems}>
        <li>
          <button onClick={() => handleClick('download')}>
            Download and Populate the Upload Template
            <i
              className={
                showInstruction.download
                  ? `${styles.arrow} ${styles.down}`
                  : `${styles.arrow} ${styles.right}`
              }
            ></i>
          </button>

          {showInstruction.download ? (
            <div className={`${styles.submenu} ${styles.submenuExpanded}`}>
              <Filetypes />
              <div className={styles.submenuItemHeader}>Please note:</div>
              <ul>
                <li className={styles.submenuItem}>
                  Each field in the template corresponds to a field in the Data
                  Transparency Standard 1.0. Please observe the “Field Options”
                  and “Format Requirements” that need to be reflected in the
                  upload template before successful upload.
                </li>
                <li className={styles.submenuItem}>
                  Any extraneous formatting such as hyperlinks, color, or
                  columns are not supported.{' '}
                </li>
                <li className={styles.submenuItem}>
                  This template treats comma in text as a delimiter. As such, it
                  is recommended to use a tab delimited .txt file format instead
                  of a comma delimited .csv (to avoid the need to remove all
                  commas if you’re copying from a pre-existing data dictionary).
                  For example, if you were to include the following free form
                  text within a comma delimited .csv cell – “This audience
                  includes opt-in search data, survey data …” - all text after
                  the “,” would be considered a separate entry within that
                  field.
                </li>
              </ul>
            </div>
          ) : (
            <div className={styles.submenu}></div>
          )}
        </li>
        <li>
          <button onClick={() => handleClick('upload')}>
            Upload Your Data
            <i
              className={
                showInstruction.upload
                  ? `${styles.arrow} ${styles.down}`
                  : `${styles.arrow} ${styles.right}`
              }
            ></i>
          </button>
          {showInstruction.upload ? (
            <div className={`${styles.submenu} ${styles.submenuExpanded}`}>
              Once all data is entered in the template and formatted per the
              Data Transparency Standard requirements, drag and drop the file
              onto the upload button. Upon submission, the tool will identify
              any fields that need to be modified to meet formatting
              requirements. Simply make your edits directly in the application
              UI, re-download the template containing your changes, then
              re-submit. If successful, your audiences and audience metadata are
              now viewable and searchable with the{' '}
              <a href="https://www.datalabel.org/">Datalabel.org</a> repository.
            </div>
          ) : (
            <div className={styles.submenu}></div>
          )}
        </li>
        <li>
          <button onClick={() => handleClick('delete')}>
            Modify or Delete Existing Data
            <i
              className={
                showInstruction.delete
                  ? `${styles.arrow} ${styles.down}`
                  : `${styles.arrow} ${styles.right}`
              }
            ></i>
          </button>
          {showInstruction.delete ? (
            <div className={`${styles.submenu} ${styles.submenuExpanded}`}>
              Need to make changes? No problem … you can modify how your data is
              viewed by maintaining and resubmitting the offline file that you
              originally uploaded (so please keep track of it!).
              <div className={styles.submenuItemHeader}>Here’s how:</div>
              <ul>
                <li className={styles.submenuItem}>
                  To modify a previous upload, simply edit the entry within the
                  original upload template and resubmit in the tool. This will
                  overwrite all previous data.
                </li>
                <li className={styles.submenuItem}>
                  To delete data from a previous upload, click on the “Settings”
                  button above the upload tool and select “Delete all my
                  uploaded audiences”. This will remove all data associated with
                  your company from the repository (based on the email domain
                  you registered with). If you’d like to add some of that data
                  back, simply edit your upload template to reflect the audience
                  metadata you’d like to keep, then resubmit.
                </li>
              </ul>
            </div>
          ) : (
            <div className={styles.submenu}></div>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default SideDrawer;
